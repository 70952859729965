import React, { useRef, useEffect, useState, useCallback } from 'react';
import { fabric } from 'fabric';
import '../polyfill'; // Adjust the path as necessary
import Sidebar from '../SideBar/sidebar';
import { setActiveCanvas } from '../../Redux/slice/canvasSlice';

import {
  handleDownloadSVG,
  handleEraserClick,
  handleDrawingMode,
  handlePathCreated,
  handleFreeDrawingClick,
  handleUploadSVG,
  handleSetColor,
  addControlPoints,
} from '../Tools/paintTools';
// import { useOriginalVisibility } from '../SideBar/SBComponents/combineMenu'
import { useDispatch, useSelector } from 'react-redux';
import { createPointControls, createEllipseControls } from '../../Utils/polygonControls';
import { Popover, Button, InputLabel, Select, MenuItem, TextField, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Slider, Box, Menu, Tooltip } from '@mui/material';
import Footer from '../Footer/footer';
import Guides from "@scena/react-guides";
import { Lock } from '@mui/icons-material';
import paper from 'paper';
import { install } from 'chart-js-fabric';

import { VennDiagramController, VennDiagramChart } from 'chartjs-chart-venn';
import { Chart, registerables } from 'chart.js';
import * as d3 from "d3";
import { VennDiagram } from 'venn.js';
import ColorPicker from 'react-best-gradient-color-picker'
import { StyledSubMenuContainer } from '../../Utils/styledComps';


Chart.register(...registerables, VennDiagramController, VennDiagramChart);

install(fabric);

const Canvas = () => {

  const dispatch = useDispatch();



  const [originalVisibility, setOriginalVisibiltiy] = useState(false);
  const handleChangeView = () => {
    setOriginalVisibiltiy(!originalVisibility);
  };
  const bg = useSelector(state => state.canvasProps.backgroundColor);
  const canvasRef = useRef(null);
  const horizonalGuidesRef = useRef();
  const verticalGuidesRef = useRef();

  const sidebarRef = useRef(null);
  const appBarRef1 = useRef(null);

  const appBarRef2 = useRef(null);
  const footerRef = useRef(null);
  const [canvases, setCanvases] = useState([]);
  const conversionRates = { px: 1, cm: 37.7952756, in: 96, feet: 1152, mm: 3.77953 };
  const [activeCanvasIndex, setActiveCanvasIndex] = useState(0);
  const [canvas, setCanvas] = useState(null);
  const [showRanges,setShowRanges] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('transparent');
  const [selectedStrokeColor, setSelectedStrokeColor] = useState('black');
  useEffect(() => {
    if (canvas) {
      console.log('Canvas found, updating objects...');
  
      const activeObjects = canvas._objects;
  
      activeObjects.forEach((obj) => {
        console.log('Before update:', obj);
  
        let fillValue = selectedColor;
        let strokeValue = selectedStrokeColor;
  
        // Check for linear gradient
        if (/linear-gradient/.test(selectedColor)) {
          fillValue = createLinearGradient(selectedColor);
        }
  
        // Check for radial gradient
        if (/radial-gradient/.test(selectedColor)) {
          fillValue = createRadialGradient(selectedColor);
        }
  
        // Similarly for stroke
        if (/linear-gradient/.test(selectedStrokeColor)) {
          strokeValue = createLinearGradient(selectedStrokeColor);
        }
  
        if (/radial-gradient/.test(selectedStrokeColor)) {
          strokeValue = createRadialGradient(selectedStrokeColor);
        }
  
        // Set the properties
        obj.set({
          fill: fillValue,
          stroke: strokeValue,
          visible: true, // Ensure visibility
        });
  
        console.log('After update:', obj);
      });
  
      canvas.renderAll(); // Force re-render the canvas
    } else {
      console.error('Canvas not found!');
    }
  }, [selectedColor, selectedStrokeColor]);
  
  // Function to create a linear gradient with angle handling
  const createLinearGradient = (gradientStr) => {
    const parsedGradient = parseGradient(gradientStr);
  
    const angle = parsedGradient.angle;
    const coords = calculateGradientCoords(angle);
  
    return new fabric.Gradient({
      type: 'linear',
      gradientUnits: 'percentage',
      coords: coords,
      colorStops: parsedGradient.stops,
    });
  };
  
  // Function to create a radial gradient
  const createRadialGradient = (gradientStr) => {
    const parsedGradient = parseGradient(gradientStr);
  
    return new fabric.Gradient({
      type: 'radial',
      gradientUnits: 'percentage',
      coords: {
        x1: 0.5, y1: 0.5, r1: 0,  // Centered radial gradient
        x2: 0.5, y2: 0.5, r2: 0.5  // Radius can be adjusted as needed
      },
      colorStops: parsedGradient.stops,
    });
  };
  
  // Function to parse the gradient string into Fabric.js color stops and angle
  const parseGradient = (gradientStr) => {
    const stops = [];
    let angle = 0;
  
    const angleMatch = gradientStr.match(/(\d+)deg/);
    if (angleMatch) {
      angle = parseFloat(angleMatch[1]);
    }
  
    const gradientParts = gradientStr.match(/rgba?\(.+?\)\s*\d+%?/g);
  
    gradientParts.forEach((part) => {
      const [color, position] = part.split(/\s+/);
      stops.push({
        offset: parseFloat(position) / 100,
        color: color,
      });
    });
  
    return { angle, stops };
  };
  
  // Function to calculate gradient coordinates based on angle
  const calculateGradientCoords = (angle) => {
    // Convert angle to radians
    const angleRad = (angle * Math.PI) / 180;
  
    // Default coords (for 0 degrees)
    let x1 = 0, y1 = 0, x2 = 1, y2 = 0;
  
    if (angle !== 0) {
      // Adjust coords based on the angle
      x1 = 0.5 + 0.5 * Math.cos(angleRad + Math.PI);
      y1 = 0.5 + 0.5 * Math.sin(angleRad + Math.PI);
      x2 = 0.5 + 0.5 * Math.cos(angleRad);
      y2 = 0.5 + 0.5 * Math.sin(angleRad);
    }
  
    return { x1, y1, x2, y2 };
  };
  

  const [opacity, setOpacity] = useState(0);
  const [opacityObj, setOpacityObj] = useState(1);
  const [isVisibleCenter, setIsVisibleCenter] = useState(false);
  const [spraySelect, setSpraySelect] = useState(false);
  let i = 0;
  const toggleVisibility = () => {
    if (canvas) {
      canvas.forEachObject((obj) => {
        if (obj.controlPoint && obj.controlText) {
          obj.controlPoint.set({ visible: !isVisibleCenter });
          obj.controlText.set({ visible: !isVisibleCenter });
        }
      })
      canvas.renderAll();
      setIsVisibleCenter(!isVisibleCenter);
    }
  };
  const [activeObj, setActiveObj] = useState(null);

  const [sprayObj, setSprayObj] = useState(null);
  const sprayObjRef = useRef(sprayObj);
  const spraySelectRef = useRef(spraySelect);

  const handleSpraying = () => {
    if (spraySelectRef.current) {
      setSprayObj(null);
      setSpraySelect(false);
      sprayObjRef.current = null;
      spraySelectRef.current = false;
    } else if (activeObj?.[0]) {
      const selectedObj = activeObj[0];
      setSprayObj(selectedObj);
      setSpraySelect(true);
      sprayObjRef.current = selectedObj;
      spraySelectRef.current = true;
    }

    console.log("handleSpraying:", spraySelectRef.current, sprayObjRef.current,numberOfCopies);
  };




  const fabricToPaperPath = (fabricObj) => {
    if (fabricObj.type !== 'path') {
      return null;
    }

    const pathData = fabricObj.path.map(segment => segment.join(' ')).join(' ');

    const path = new paper.Path(pathData);

    const scaleX = fabricObj.scaleX || 1;
    const scaleY = fabricObj.scaleY || 1;
    const skewX = fabricObj.skewX || 0;
    const skewY = fabricObj.skewY || 0;
    const angle = fabricObj.angle || 0;
    const translateX = fabricObj.left || 0;
    const translateY = fabricObj.top || 0;
    console.log('fabricToPaperPath: fabricPath properties:', angle, scaleX, scaleY, skewX, skewY, translateX, translateY, fabricObj.width, fabricObj.height);

    const matrix = new paper.Matrix();

    // matrix.translate(translateX, translateY);
    matrix.rotate(angle);
    matrix.skew(skewX, skewY);
    matrix.scale(scaleX, scaleY);

    path.transform(matrix);

    let centerX = fabricObj.controlPoint.left;
    let centerY = fabricObj.controlPoint.top;
    i += 1;
    console.log(`fabricToPaperPath: centerX,centerY of ${i}: `, centerX, centerY, angle, translateX, translateY)
    path.position = new paper.Point(centerX, centerY);

    return path;
  };




  const paperToFabricPath = (paperPath, fabricObj) => {
    // Export SVG path data
    const pathData = paperPath.exportSVG({ asString: true });
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(pathData, 'image/svg+xml');
    const pathElement = svgDoc.querySelector('path');
    const d = pathElement.getAttribute('d');

    // Extract the position from the Paper.js path
    const position = paperPath.position;

    console.log('Paper.js Path (before converting to Fabric.js):', pathData);

    // Create a new Fabric.js path
    const canvasPoint = canvas.calcViewportBoundaries();
    const rightPointx = canvasPoint.br.x;
    const fabricPath = new fabric.Path(d, {
      fill: fabricObj.fill,
      stroke: fabricObj.stroke,
      originX: 'center',
      originY: 'center',
      left: originalVisibility ?
        (2 * position.x >= rightPointx ? (1 / 2) * position.x : 2 * position.x + fabricObj.width + 2)
        : position.x,
      top: position.y,
      strokeWidth: fabricObj.strokeWidth
    });

    console.log('Fabric.js Path (after converting from Paper.js):', fabricPath.toSVG());

    return fabricPath;
  };


  const combineSelectedPaths = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    const canvasElement = document.createElement('canvas');
    canvasElement.width = size.width;
    canvasElement.height = size.height;

    // Set up Paper.js with the newly created canvas
    paper.setup(canvasElement);


    console.log("combineSelectedPaths: paper: ", paper)
    // Convert Fabric.js objects to Paper.js paths with correct transformations
    const paperPaths = activeObj.map(fabricToPaperPath);

    // Log the Paper.js paths data
    paperPaths.forEach((path, index) => {
      console.log(`Paper.js Path ${index} (before union):`, path.exportSVG({ asString: true }));
    });

    let combinedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      combinedPath = combinedPath.unite(paperPaths[i]);
    }

    console.log('Combined Paper.js Path (after union):', combinedPath.exportSVG({ asString: true }));

    const combinedFabricPath = paperToFabricPath(combinedPath, activeObj[0]);

    console.log('Combined Fabric.js Path (after converting from Paper.js):', combinedFabricPath.toSVG());

    canvas.add(combinedFabricPath);

    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));

    canvas.discardActiveObject();
    canvas.setActiveObject(combinedFabricPath);
    canvas.requestRenderAll();
  };

  const combineSelectedObjectsFragmentation = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    paper.setup(document.createElement('canvas'));

    let paperPaths = activeObj.map(fabricToPaperPath);

    const originalPath = paperPaths[0].clone();

    let intersectionPaths = [];

    for (let i = 1; i < paperPaths.length; i++) {
      let intersection = paperPaths[0].intersect(paperPaths[i]);
      if (intersection) {
        intersectionPaths.push(intersection);
      }
    }

    let subtractedPath = originalPath;
    intersectionPaths.forEach(intersection => {
      subtractedPath = subtractedPath.subtract(intersection);
    });

    let additionalPaths = [];

    for (let i = 1; i < paperPaths.length; i++) {
      let path = paperPaths[i].clone();
      intersectionPaths.forEach(intersection => {
        path = path.subtract(intersection);
      });
      if (!path.isEmpty()) {
        additionalPaths.push(path);
      }
    }

    const subtractedPathFabric = paperToFabricPath(subtractedPath, activeObj[0]);
    const intersectionFabricPaths = intersectionPaths.map(intersection => paperToFabricPath(intersection, activeObj[0]));
    const additionalFabricPaths = additionalPaths.map(path => paperToFabricPath(path, activeObj[0]));

    canvas.add(subtractedPathFabric);
    intersectionFabricPaths.forEach(path => canvas.add(path));
    additionalFabricPaths.forEach(path => canvas.add(path));

    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));

    canvas.discardActiveObject();
    canvas.setActiveObject(subtractedPathFabric);
    canvas.requestRenderAll();
  };




  const combineSelectedObjectsIntersection = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    paper.setup(document.createElement('canvas'));

    let paperPaths = activeObj.map(fabricToPaperPath);

    let intersectedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      intersectedPath = intersectedPath.intersect(paperPaths[i]);
    }

    const intersectedFabricPath = paperToFabricPath(intersectedPath, activeObj[0]);

    canvas.add(intersectedFabricPath);

    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));

    canvas.discardActiveObject();
    canvas.setActiveObject(intersectedFabricPath);
    canvas.requestRenderAll();
  };
  const combineSelectedObjectsDifference = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    paper.setup(document.createElement('canvas'));

    // Convert selected Fabric.js objects to Paper.js paths
    let paperPaths = activeObj.map(fabricToPaperPath);

    // Perform difference operation on paths using Paper.js
    let differencePath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      differencePath = differencePath.subtract(paperPaths[i]);
    }

    // Convert the resulting Paper.js path back to a Fabric.js path
    const differenceFabricPath = paperToFabricPath(differencePath, activeObj[0]);

    // Add the resulting path to the Fabric.js canvas
    canvas.add(differenceFabricPath);

    // Remove original paths from the Fabric.js canvas
    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));

    // Update the active object and render the canvas
    canvas.discardActiveObject();
    canvas.setActiveObject(differenceFabricPath);
    canvas.requestRenderAll();
  };

  const combineSelectedPathsExclusion = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    paper.setup(document.createElement('canvas'));

    // Convert selected Fabric.js objects to Paper.js paths
    let paperPaths = activeObj.map(fabricToPaperPath);

    // Perform exclusion (difference) on the paths using Paper.js
    let excludedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      excludedPath = excludedPath.exclude(paperPaths[i]);
    }

    // Convert the resulting Paper.js path back to a Fabric.js path
    const excludedFabricPath = paperToFabricPath(excludedPath, activeObj[0]);

    // Add the excluded path to the Fabric.js canvas
    canvas.add(excludedFabricPath);

    // Remove the original paths from the Fabric.js canvas
    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));

    // Update the active object and render the canvas
    canvas.discardActiveObject();
    canvas.setActiveObject(excludedFabricPath);
    canvas.requestRenderAll();
  };
  const changeOpacity = (val) => {
    if (canvas) {
      console.log("opacity: ", canvas, opacity, val);
      if (canvas.backgroundImage) {
        console.log("opacity if bgImage: ", canvas.backgroundImage, opacity, val);
        canvas.backgroundImage.set('opacity', val);
      } else {
        const opaqueRect = new fabric.Rect({
          left: 0,
          top: 0,
          width: canvas.width / zoom,
          height: canvas.height / zoom,
          fill: bg,
          opacity: val
        });
        console.log("opacity: if no bgImage", opaqueRect);

        canvas.setBackgroundImage(opaqueRect.toDataURL(), canvas.renderAll.bind(canvas));
      }
      setOpacity(val);
      canvas.renderAll();
    }
  }

  const changeOpacityOfObj = (val) => {
    var obj = canvas.getActiveObject();
    if(obj){
      obj.set({
        opacity: val
      });
      setOpacityObj(val);
      canvas.renderAll();
    }    
  }
  // useEffect(() => {
  //   if (canvas) {
  //     console.log("opacity: ", canvas, opacity);
  //     if (canvas.backgroundImage) {
  //       console.log("opacity if bgImage: ", canvas.backgroundImage, opacity);
  //       canvas.backgroundImage.opacity = opacity;
  //       canvas.renderAll();
  //     } else {
  //       const opaqueRect = new fabric.Rect({
  //         left: 0,
  //         top: 0,
  //         width: canvas.width,
  //         height: canvas.height,
  //         fill: bg,
  //         opacity: opacity
  //       });
  //       console.log("opacity: if no bgImage", opaqueRect);

  //       canvas.setBackgroundImage(opaqueRect.toDataURL(), canvas.renderAll.bind(canvas));
  //       canvas.renderAll();
  //     }
  //   }
  // }, [opacity]);

  const [size, setSize] = useState({
    width: 595,
    height: 842
  });
  const [zoom, setZoom] = useState(0.7);
  const [path, setPath] = useState(null);
  const [canvasImage, setCanvasImage] = useState([]);
  const captureInitialCanvasImages = () => {
    const initialImages = canvases.map(canvas => canvas.toDataURL({ format: 'png', quality: 1.0 }));
    setCanvasImage(initialImages);
  };

  useEffect(() => {
    if (canvases.length > 0) {
      captureInitialCanvasImages();
      console.log("first load canvases: ", canvases);
    }
  }, [canvases]);

  const adjustCanvasContainerDimensions = () => {
    const appBarHeight1 = appBarRef1.current ? appBarRef1.current.clientHeight : 0;
    const appBarHeight2 = appBarRef2.current ? appBarRef2.current.clientHeight : 0;
    const combinedAppBarHeight = appBarHeight1 + appBarHeight2;
    const footerHeight = footerRef.current ? footerRef.current.clientHeight : 0;
    const sidebarWidth = sidebarRef.current ? sidebarRef.current.clientWidth : 0;
    const container = document.getElementById('canvas-container');
    console.log("adjustCanvasContainerDimensions:", combinedAppBarHeight, footerHeight, sidebarWidth)
    if (container) {
      const containerHeight = window.innerHeight - combinedAppBarHeight - footerHeight;
      const containerWidth = window.innerWidth - sidebarWidth;
      container.style.height = `${containerHeight}px`;
      container.style.width = `${containerWidth}px`;
      // container.style.marginLeft = `${sidebarWidth}px`;
      container.style.marginTop = `${combinedAppBarHeight}px`;
      container.style.paddingTop = '40px';

      console.log("adjustCanvasContainerDimensions: containerHeight,containerHeight,container.style:", containerHeight, containerHeight, container.style)

    }
  };



  useEffect(() => {
    adjustCanvasContainerDimensions();
  }, []);


  const handleZoomChange = (event, newValue) => {
    setZoom(newValue / 100);
    if (canvas) {
      canvas.setZoom(newValue / 100);
      canvas.setViewportTransform(canvas.viewportTransform);
      updateCanvasDimensions(size.width, size.height, newValue / 100);
    }
  };

  const updateCanvasDimensions = (width, height, zoomLevel) => {
    if (canvas) {
      canvas.setDimensions({ width: width * zoomLevel, height: height * zoomLevel });
    }
  };


  const handlePredefinedSizeChange = (size, lanes = 1) => {
    let heightInPixels;
    let widthInPixels;

    switch (size) {
      case 'A5':
        heightInPixels = 595;
        widthInPixels = 420;
        break;
      case 'A4':
        heightInPixels = 842;
        widthInPixels = 595;
        break;
      case 'A3':
        heightInPixels = 1191;
        widthInPixels = 842;
        break;
      case 'A2':
        heightInPixels = 1684;
        widthInPixels = 1191;
        break;
      case '3X4':
        heightInPixels = 1200;
        widthInPixels = 900;
        break;
      case '4X3':
        heightInPixels = 900;
        widthInPixels = 1200;
        break;
      case '54X40':
        heightInPixels = 5184;
        widthInPixels = 3840;
        break;
      case '40X54':
        heightInPixels = 3840;
        widthInPixels = 5184;
        break;
      case '48X36':
        heightInPixels = 4608;
        widthInPixels = 3456;
        break;
      case '36X48':
        heightInPixels = 3456;
        widthInPixels = 4608;
        break;
      case 'fullBanner':
        heightInPixels = 60;
        widthInPixels = 468;
        break;
      case 'halfBanner':
        heightInPixels = 60;
        widthInPixels = 234;
        break;
      case 'skyscraper':
        heightInPixels = 600;
        widthInPixels = 120;
        break;
      case 'wideSkyscraper':
        heightInPixels = 600;
        widthInPixels = 160;
        break;
      case 'mediumBanner':
        heightInPixels = 250;
        widthInPixels = 300;
        break;
      case 'leaderboard':
        heightInPixels = 90;
        widthInPixels = 728;
        break;
      case 'halfPage':
        heightInPixels = 600;
        widthInPixels = 300;
        break;
      case 'largeLeaderboard':
        heightInPixels = 90;
        widthInPixels = 970;
        break;
      case 'billBoard':
        heightInPixels = 250;
        widthInPixels = 970;
        break;
      case 'largeRectangle':
        heightInPixels = 280;
        widthInPixels = 336;
        break;
      case 'smallSquare':
        heightInPixels = 200;
        widthInPixels = 200;
        break;
      case 'square':
        heightInPixels = 250;
        widthInPixels = 250;
        break;
      case 'verticalBanner':
        heightInPixels = 240;
        widthInPixels = 120;
        break;
      case 'portrait':
        heightInPixels = 1050;
        widthInPixels = 300;
        break;

      case 'linkedIn':
        heightInPixels = 627;
        widthInPixels = 1200;
        break;
      case 'instagram':
        heightInPixels = 1080;
        widthInPixels = 1080;
        break;
      case 'facebook':
        heightInPixels = 630;
        widthInPixels = 1200;
        break;
      case 'twitter':
        heightInPixels = 675;
        widthInPixels = 1200;
        break;

      default:
        break;
    }

    setSize({
      height: heightInPixels,
      width: widthInPixels
    });

    if (canvas) {
      updateCanvasDimensions(widthInPixels, heightInPixels, zoom);

      const shouldConsiderLanes = ['3X4', '4X3', '54X40', '40X54', '48X36', '36X48'].includes(size);
      if (shouldConsiderLanes) {

        if (lanes === 1) {
          addTextBoxWithBorder('Title', widthInPixels * 0.1, heightInPixels * 0.005, widthInPixels * 0.8, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body', widthInPixels * 0.1, heightInPixels * 0.105, widthInPixels * 0.8, heightInPixels * 0.8, 18);
        } else if (lanes === 2) {
          addTextBoxWithBorder('Title 1', widthInPixels * 0.005, heightInPixels * 0.005, widthInPixels * 0.49, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 1', widthInPixels * 0.005, heightInPixels * 0.1, widthInPixels * 0.49, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Title 2', widthInPixels * 0.505, heightInPixels * 0.005, widthInPixels * 0.49, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 2', widthInPixels * 0.505, heightInPixels * 0.105, widthInPixels * 0.49, heightInPixels * 0.8, 18);

        } else if (lanes === 3) {
          addTextBoxWithBorder('Title 1', widthInPixels * 0.0025, heightInPixels * 0.005, widthInPixels * 0.32, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 1', widthInPixels * 0.0025, heightInPixels * 0.105, widthInPixels * 0.32, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Title 2', widthInPixels * 0.34, heightInPixels * 0.005, widthInPixels * 0.32, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 2', widthInPixels * 0.34, heightInPixels * 0.105, widthInPixels * 0.32, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Title 3', widthInPixels * 0.675, heightInPixels * 0.005, widthInPixels * 0.32, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 3', widthInPixels * 0.675, heightInPixels * 0.105, widthInPixels * 0.32, heightInPixels * 0.8, 18);
        }
      }
      canvas.renderAll();
    }
  };

  const addTextBoxWithBorder = (text, left, top, width, height, fontSize) => {
    const rect = new fabric.Rect({
      left: left,
      top: top,
      width: width,
      height: height,
      angle: 0,
      strokeWidth: 1,
      stroke: 'black',
      fill: 'transparent',
      strokeDashArray: [5, 5],
      hasBorders: true,
      hasControls: true,
      transparentCorners: false
    });

    const textBox = new fabric.Textbox(text, {
      left: left,
      top: top,
      width: width,
      height: height,
      textAlign: 'center',
      fontSize: fontSize,
      editable: true,
      hasBorders: false,
      hasControls: false,
      class: 'textBox',
      overflow: 'hidden',
      textBackgroundColor: 'transparent',
      splitByGrapheme: true
    });

    canvas.add(rect);
    canvas.add(textBox);

    textBox.bringToFront();
    const syncTextBoxWithRect = () => {
      textBox.set({
        left: rect.left,
        top: rect.top,
        scaleX: rect.scaleX,
        scaleY: rect.scaleY,
        angle: rect.angle,
        skewX: rect.skewX,
        skewY: rect.skewY
      });
      textBox.setCoords();
      canvas.renderAll();
    };

    // Synchronize the textbox with the rect on transform events
    rect.on('moving', syncTextBoxWithRect);
    rect.on('scaling', syncTextBoxWithRect);
    rect.on('rotating', syncTextBoxWithRect);
    rect.on('skewing', syncTextBoxWithRect);
  };







  const handleSizeChange = (newSize) => {
    if (!newSize) return;

    let heightInPixels = parseFloat(newSize.height);
    let widthInPixels = parseFloat(newSize.width);

    switch (newSize.unit) {
      case 'px X px':
        break;
      case 'cm X cm':
        heightInPixels *= 37.7952756;
        widthInPixels *= 37.7952756;
        break;
      case 'mm X mm':
        heightInPixels *= 3.77952756;
        widthInPixels *= 3.77952756;
        break;
      case 'inch X inch':
        heightInPixels *= 96;
        widthInPixels *= 96;
        break;
      case 'feet x feet':
        heightInPixels *= 1152;
        widthInPixels *= 1152;
        break;
      default:
        break;
    }

    setSize({
      height: heightInPixels,
      width: widthInPixels
    });

    if (canvas) {
      updateCanvasDimensions(widthInPixels, heightInPixels, zoom);
    }
  };
  const handleGroupObject = (canvas, group) => {
    if (group.type === 'group') {
      const objectsInGroup = group._objects.slice();
      const groupLeft = group.left;
      const groupTop = group.top;
      const groupScaleX = group.scaleX;
      const groupScaleY = group.scaleY;

      canvas.discardActiveObject();
      canvas.remove(group);

      objectsInGroup.forEach(obj => {
        obj.selectable = true;
        obj.hasControls = true;
        obj.hasBorders = true;
        obj.left = obj.left * groupScaleX + groupLeft;
        obj.top = obj.top * groupScaleY + groupTop;
        obj.scaleX *= groupScaleX;
        obj.scaleY *= groupScaleY;

        obj.group = null;

        canvas.add(obj);
      });

      canvas.renderAll();
    }
  };

  // useEffect(() => {
  //   if (canvases.length === 0) {
  //     addNewCanvas();
  //   }
  // }, []);
  const removeCanvas = () => {
    const newCanvases = canvases.filter((val, index) => index !== activeCanvasIndex)
  }
  const updateGuides = () => {
    const canvasContainer = document.getElementById('canvas-container');
    if (canvasContainer) {
      const canvasElement = canvasContainer.querySelector(`div.canvas-container`);
      console.log('updateGuides: canvasContainer, canvasElement: ',canvasContainer, canvasElement);

      if (canvasElement) {
        const canvasRect = canvasElement.getBoundingClientRect();
        const containerRect = canvasContainer.getBoundingClientRect();
        const offsetX = (canvasRect.x - containerRect.x - 30) / zoom;
        const offsetY = (canvasRect.y - containerRect.y - 30) / zoom;

        console.log('updateGuides: canvasRect, containerRect: ',canvasRect,canvasContainer.style,containerRect);           
        
        if (horizonalGuidesRef.current) {
          horizonalGuidesRef.current.scroll(-offsetX ,zoom); 
          horizonalGuidesRef.current.scrollGuides(-offsetY,zoom);
        }
        if (verticalGuidesRef.current) {
          verticalGuidesRef.current.scroll(-offsetY,zoom); 
          verticalGuidesRef.current.scrollGuides(-offsetX,zoom);

        }
      }
    }
  };
  useEffect(() => {
    
  
    updateGuides();

    window.addEventListener("resize", updateGuides);

    return () => {
      window.removeEventListener("resize", updateGuides);
    };
  }, [canvases, zoom, window, size]);


  const addNewCanvas = () => {
    const newIndex = canvases.length;
    const newCanvas = createNewCanvas(canvasRef.current, bg, size, newIndex);
    // canvasRef.current = null;
    setCanvases((prevCanvases) => [...prevCanvases, newCanvas]);
    setActiveCanvasIndex(activeCanvasIndex);
    // setCanvas(canvas);

    console.log("addNewCanvas:", newCanvas, canvases);
  };

  useEffect(() => {
    const removeCanvasWithoutLowerClass = () => {
      const canvasContainers = document.querySelectorAll('#canvas-container > canvas');
      canvasContainers.forEach(canvas => {
        if (!canvas.classList.contains('lower-canvas') && canvas.id === 'canvas0') {
          canvas.parentNode.removeChild(canvas);
          console.log(`Removed canvas ${canvas.id} from DOM.`);
        }
      });
    };

    if (canvases.length > 0) {
      removeCanvasWithoutLowerClass();
    }

  }, [canvases.length < 2]);


  const createNewCanvas = (ref, bg, size, index) => {
    const newCanvasElement = document.createElement('canvas');
    console.log('createNewCanvas: newCanvasElement: 1', newCanvasElement);
    newCanvasElement.setAttribute('id', `canvas${index}`);

    ref.appendChild(newCanvasElement);
    console.log('createNewCanvas: ref: 1', ref);
    console.log('createNewCanvas: newCanvasElement: 2', newCanvasElement);
    const newCanvas = new fabric.Canvas(newCanvasElement, {
      id: `canvas-${index}`,
      isDrawingMode: false,
      backgroundColor: bg,
      controlsAboveOverlay: true,
      width: size.width * zoom,
      height: size.height * zoom,
    });


    newCanvas.setZoom(zoom)
    newCanvas.freeDrawingBrush = new fabric.PencilBrush(newCanvas);
    newCanvas.freeDrawingBrush.color = 'black';
    newCanvas.freeDrawingBrush.width = 1;

    if (canvases.length > 0 && index !== activeCanvasIndex) {
      newCanvasElement.style.display = 'none';
      newCanvasElement.parentElement.style.display = 'none';
    }
    console.log("createNewCanvas: ", newCanvas)
    return newCanvas;
  };
  const [custControls, setCustControls] = useState(false);

  useEffect(() => {
    if (canvases.length > 0) {
      canvases.forEach((newCanvas, index) => {
        if (index == activeCanvasIndex) {
          console.log(`Attaching event listeners to Canvas ${index + 1}`);
          if (!newCanvas.__eventListenersAttached) {
            newCanvas.__eventListenersAttached = true;

            console.log("newCanvas & index: ", newCanvas, index);

            newCanvas.forEachObject(obj => {
              obj.selectable = true;
              console.log("forEach: object:", obj)
            });

            const handleResize = () => {
              const outerCanvasContainer = document.getElementById('canvas-container');

              const ratio = newCanvas.getWidth() / newCanvas.getHeight();
              const containerWidth = outerCanvasContainer.clientWidth;
              const containerHeight = outerCanvasContainer.clientHeight;

              const scale = containerWidth / newCanvas.getWidth();
              const zoom = newCanvas.getZoom() * scale;
              // newCanvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });
              adjustCanvasContainerDimensions();
            };

            window.addEventListener('resize', handleResize);

            const handlePathCreated = (e) => {
              console.log("handlePathCreated: e,canvas:", e, canvas);

              if (index === activeCanvasIndex) {
                const path = e.path;
                setPath(path);
                console.log("handlePathCreated: Path created:", path);
              }
            };


            let initialAngle = 0;
            let initialMouseAngle = 0;
            let anchorPoint = { x: 0, y: 0 };
            function throttle(func, limit) {
              let lastFunc;
              let lastRan;
              return function (...args) {
                if (!lastRan) {
                  func.apply(this, args);
                  lastRan = Date.now();
                } else {
                  clearTimeout(lastFunc);
                  lastFunc = setTimeout(() => {
                    if (Date.now() - lastRan >= limit) {
                      func.apply(this, args);
                      lastRan = Date.now();
                    }
                  }, limit - (Date.now() - lastRan));
                }
              };
            }

            function initializeRotationHandler(eventData, transform, x, y) {
              const target = transform.target;
              initialAngle = target.angle;

              initialMouseAngle = Math.atan2(y - anchorPoint.y, x - anchorPoint.x);

              const boundingBox = target.getBoundingRect();
              anchorPoint = {
                x: boundingBox.left + boundingBox.width / 2,
                y: boundingBox.top + boundingBox.height / 2
              };
              console.log("initialRotation: ", anchorPoint, boundingBox, target)
              return true;
            }
            const rotationHandler = throttle(function (eventData, transform, x, y) {
              const target = transform.target;
              const anchor = new fabric.Point(anchorPoint.x, anchorPoint.y);
              // Compute the current mouse angle relative to the anchor point
              const currentMouseAngle = Math.atan2(y - anchor.y, x - anchor.x);
              const relativeMouseAngle = currentMouseAngle - initialMouseAngle;
              const newAngle = fabric.util.radiansToDegrees(relativeMouseAngle + initialAngle);
              console.log("rotationHandler: newAngle after def: newAngle, relativeMouseAngle, currentMouseAngle, initialAngle", newAngle, relativeMouseAngle, currentMouseAngle, initialAngle);
              const control = target.controls.customRotationControlsCenter;
              console.log("rotationHandler:")

              // Calculate the new center point after rotation
              const radian = fabric.util.degreesToRadians(newAngle - target.angle);
              const rotatedCenter = fabric.util.rotatePoint(target.getCenterPoint(), anchor, radian);
              console.log("rotationHandler: rotatedCenter: target.getCenterPoint(), anchor, radian:", rotatedCenter, target.getCenterPoint(), anchor, radian);

              // Apply the new angle and position
              target.set({
                angle: newAngle,
                left: rotatedCenter.x,
                top: rotatedCenter.y,
                originX: 'center',
                originY: 'center'
              });
              console.log("rotationHandler: all at end:", newAngle, rotatedCenter, target.originX, target.originY)
              target.setCoords();
              canvas.requestRenderAll();
              return true;
            }, 2); // Adjust the limit value to control the frequency of updates

            // function dragHandler(eventData, transform, x, y) {
            //   const target = transform.target;
            //   const control = target.controls.customRotationControlsCenter;

            //   // Get the pointer position in canvas coordinates
            //   const pointer = canvas.getPointer(eventData.e);

            //   // Get the inverse of the object's transformation matrix
            //   const invertedMatrix = fabric.util.invertTransform(target.calcTransformMatrix());

            //   // Apply the inverse matrix to the pointer to get local coordinates
            //   const localPoint = fabric.util.transformPoint(new fabric.Point(pointer.x, pointer.y), invertedMatrix);

            //   // Constrain the local coordinates within the object's bounding box
            //   const newX = Math.max(-target.width / 2, Math.min(localPoint.x, target.width / 2));
            //   const newY = Math.max(-target.height / 2, Math.min(localPoint.y, target.height / 2));

            //   // Convert the constrained local coordinates back to canvas coordinates
            //   const controlPoint = fabric.util.transformPoint(new fabric.Point(newX, newY), target.calcTransformMatrix());

            //   // Update the anchor point to be the constrained local coordinates
            //   anchorPoint = { x: controlPoint.x, y: controlPoint.y };

            //   // Log the positions for debugging
            //   console.log('dragHandler: Pointer:', pointer);
            //   console.log('dragHandler: Local Point:', localPoint);
            //   console.log('dragHandler: Constrained Local Point:', { x: newX, y: newY });
            //   console.log('dragHandler: Control Point:', controlPoint);
            //   console.log('dragHandler: Anchor Point:', anchorPoint);

            //   // Update the control's position relative to the object's dimensions
            //   control.x = newX / target.width;
            //   control.y = newY / target.height;
            //   console.log('dragHandler: Control Final:', control.x, control.y);

            //   // Update the object's coordinates and request render
            //   target.setCoords();
            //   canvas.requestRenderAll();

            //   return true;
            // }
            function dragHandler(eventData, transform, x, y) {
              const target = transform.target;
              const control = target.controls.customRotationControlsCenter;

              // Get the pointer position in canvas coordinates
              const pointer = canvas.getPointer(eventData.e);

              // Get the inverse of the object's transformation matrix
              const invertedMatrix = fabric.util.invertTransform(target.calcTransformMatrix());

              // Apply the inverse matrix to the pointer to get local coordinates
              const localPoint = fabric.util.transformPoint(new fabric.Point(pointer.x, pointer.y), invertedMatrix);

              // Constrain the local coordinates within the object's bounding box
              const newX = Math.max(-target.width / 2, Math.min(localPoint.x, target.width / 2));
              const newY = Math.max(-target.height / 2, Math.min(localPoint.y, target.height / 2));

              // Update the control's position relative to the object's dimensions
              control.x = newX / target.width;
              control.y = newY / target.height;

              // Convert the local coordinates back to canvas coordinates for the anchor point
              const controlPoint = fabric.util.transformPoint(
                { x: newX, y: newY },
                fabric.util.multiplyTransformMatrices(
                  target.calcTransformMatrix(),
                  canvas.viewportTransform
                )
              );
              anchorPoint = { x: controlPoint.x, y: controlPoint.y };

              // Update the object's coordinates and request render
              target.setCoords();
              canvas.requestRenderAll();

              return true;
            }




            let initialSkewX = 0;
            let initialSkewY = 0;

            const initializeSkewHandler = (eventData, transform) => {
              const target = transform.target;
              if (!target || !(target instanceof fabric.Object)) {
                console.error("Target is not a valid Fabric.js object");
                return;
              }
              initialSkewX = target.skewX || 0;
              initialSkewY = target.skewY || 0;
            };

            canvas.on('mouse:down', (opt) => {
              const { e, transform } = opt;
              if (transform && transform.corner) {
                initializeSkewHandler(e, transform);
              }
              handleCanvasClick(opt);
            });



            const skewHandler = (eventData, transform) => {
              const target = transform.target;

              if (!target || !(target instanceof fabric.Object)) {
                console.error("Target is not a valid Fabric.js object");
                return;
              }

              // Get the pointer position directly from the event
              const pointer = target.canvas.getPointer(eventData.e);
              if (!pointer) {
                console.error("Pointer position could not be retrieved");
                return;
              }

              // Get the initial bounding box of the target
              const bbox = target.getBoundingRect(true);
              const centerX = bbox.left + bbox.width / 2;
              const centerY = bbox.top + bbox.height / 2;

              // Calculate the deltas from the center of the bounding box
              const deltaX = pointer.x - centerX;
              const deltaY = pointer.y - centerY;

              // Determine which skew direction to adjust based on the control corner
              let newSkewX = initialSkewX;
              let newSkewY = initialSkewY;
              let sensitivity = 30;
              switch (transform.corner) {
                case 'customSkewControls1':
                  newSkewX = initialSkewX + (deltaX * sensitivity / target.width);
                  break;
                case 'customSkewControls2':
                  newSkewX = initialSkewX - (deltaX * sensitivity / target.width);
                  break;
                case 'customSkewControls':
                  newSkewY = initialSkewY + (deltaY * sensitivity / target.height);
                  break;
                case 'customSkewControls3':
                  newSkewY = initialSkewY - (deltaY * sensitivity / target.height);
                  break;
              }

              // Update the target with new skew values
              target.set({
                skewX: newSkewX,
                skewY: newSkewY
              });

              target.setCoords();
              target.canvas.requestRenderAll();
            };




            const img = document.createElement('img');
            const cornerImg = document.createElement('img');
            const sideImageHorizontal = document.createElement('img');
            const sideImageVertical = document.createElement('img');


            const cornerIcons = "/rotate-option-svgrepo-com.svg";
            const sideIconHorizontal = "/expand-items-svgrepo-com.svg";
            const sideIconVertical = "/expand-vertical.svg";
            const deleteIcon = "/anchor-svgrepo-com.svg"

            img.src = deleteIcon;
            cornerImg.src = cornerIcons;
            sideImageHorizontal.src = sideIconHorizontal;
            sideImageVertical.src = sideIconVertical;
            function renderIcon(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(img, (-size / 2), (-size / 2), size, size);
              ctx.restore();
            }

            function renderIconCorner(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(cornerImg, -size / 2, -size / 2, size, size);
              ctx.restore();
            }
            function renderIconSides(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(sideImageHorizontal, -size / 2, -size / 2, size, size);
              ctx.restore();
            }
            function renderIconSidesVertical(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(sideImageVertical, -size / 2, -size / 2, size, size);
              ctx.restore();
            }

            let controlOptions = {
              cornerSize: 18,
              actionHandler: rotationHandler,
              visible: custControls
            }


            fabric.Object.prototype.set({
              transparentCorners: false,
              cornerStyle: 'circle',
              cornerColor: 'lightblue',
              cornerStrokeColor: 'darkblue',
              cornerSize: 12
            });



            fabric.Object.prototype.controls.customRotationControls = new fabric.Control({
              ...controlOptions, x: 0.5, y: 0.5, render: renderIconCorner,
              cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls1 = new fabric.Control({
              ...controlOptions, x: -0.5, y: 0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls2 = new fabric.Control({
              ...controlOptions, x: 0.5, y: -0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls3 = new fabric.Control({
              ...controlOptions, x: -0.5, y: -0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customSkewControls = new fabric.Control({ ...controlOptions, x: 0.5, y: 0, actionName: 'skew', render: renderIconSides, actionHandler: skewHandler, cursorStyle: 's-resize' });
            fabric.Object.prototype.controls.customSkewControls1 = new fabric.Control({ ...controlOptions, x: 0, y: 0.5, actionName: 'skew', render: renderIconSidesVertical, actionHandler: skewHandler, cursorStyle: 'w-resize' });
            fabric.Object.prototype.controls.customSkewControls2 = new fabric.Control({ ...controlOptions, x: 0, y: -0.5, actionName: 'skew', render: renderIconSidesVertical, actionHandler: skewHandler, cursorStyle: 'w-resize' });
            fabric.Object.prototype.controls.customSkewControls3 = new fabric.Control({ ...controlOptions, x: -0.5, y: 0, actionName: 'skew', render: renderIconSides, actionHandler: skewHandler, cursorStyle: 's-resize' });

            fabric.Object.prototype.controls.customRotationControlsCenter = new fabric.Control({
              ...controlOptions, x: 0, y: 0, actionHandler: dragHandler, render: renderIcon, cursorStyle: 'crosshair',
            });

            newCanvas.on('mouse:down', (opt) => {
              const { e, transform } = opt;
              if (transform && anchorPoint.x === 0 && anchorPoint.y === 0) {
                initializeRotationHandler(e, transform, e.clientX, e.clientY);
              }
            });

            const handleSelectionCreated = (e) => {
              if (index === activeCanvasIndex) {
                const selectedObject = e.selected;
                if (selectedObject) {
                  console.log("fabricToPaperPath: handleSelectionCreated:", selectedObject[0].top, selectedObject[0].left, selectedObject[0].width, selectedObject[0].height, "selectedObject[0] & selectedObject", selectedObject[0], selectedObject)

                  setPath(selectedObject[0]); // Assuming setPath is a state setter function
                }
              }
            };

            const handleCanvasChange = (index) => {
              captureInitialCanvasImages();
              console.log("handleCanvasChange: canvas & canvasImage: ", canvas, canvasImage)

            };
            let isDragging = false;
            let lastPosX = 0;
            let lastPosY = 0;
            const handleMouseDown = (opt) => {
              const evt = opt.e;

              let mainCanvas = canvases[activeCanvasIndex];
              // Get all canvas elements in the DOM
              const canvasElements = document.querySelectorAll('canvas');
              console.log("handleMoseDown: actual:", canvas);

              // Iterate through each canvas element to find the clicked canvas
              canvasElements.forEach(canvasElement => {
                const canvasRect = canvasElement.getBoundingClientRect();

                // Check if the click coordinates are within the bounds of this canvas element
                if (
                  evt.clientX >= canvasRect.left &&
                  evt.clientX <= canvasRect.right &&
                  evt.clientY >= canvasRect.top &&
                  evt.clientY <= canvasRect.bottom
                ) {
                  // Find the corresponding fabric canvas instance from your canvases array
                  const clickedCanvas = canvases.find(canvas => canvas.getElement() === canvasElement);

                  if (clickedCanvas) {
                    console.log("handleMoseDown: Clicked Canvas & actual:", clickedCanvas, canvas);
                    // Perform actions based on the clicked canvas
                  } else {
                    console.log("handleMoseDown: No fabric.js canvas instance found for clicked canvas element.");
                  }
                }
              });

              if (evt.altKey === true) {
                isDragging = true;
                // Disable selection if alt key is pressed
                canvas.selection = false; // Update with the correct canvas instance if needed
                lastPosX = evt.clientX;
                lastPosY = evt.clientY;
              }
            };



            const handleMouseMove = (opt) => {
              if (isDragging) {
                const e = opt.e;
                const vpt = newCanvas.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                newCanvas.requestRenderAll();
                lastPosX = e.clientX;
                lastPosY = e.clientY;
              }
            };


            const handleMouseUp = () => {
              newCanvas.setViewportTransform(newCanvas.viewportTransform);
              isDragging = false;
              newCanvas.selection = true;
            };

            const handleSelectionUpdated = (e) => {
              if (index === activeCanvasIndex) {
                const selectedObject = e.target;
                if (selectedObject && selectedObject.type === 'path') {
                  setPath(selectedObject);
                }
              }
            };
            const handleMouseDoubleClick = (event) => {
              if (index === activeCanvasIndex && event.target) {

                if (event.target.type === 'path') {
                  newCanvas.isDrawingMode = false;
                  newCanvas.selection = true;
                  createPointControls(newCanvas, event.target); // Assuming createPointControls is a function
                } else if (event.target.type === 'group') {
                  handleGroupObject(newCanvas, event.target); // Assuming handleGroupObject is a function
                } else if (event.target.type === 'ellipse') {
                  newCanvas.isDrawingMode = false;
                  newCanvas.selection = true;
                  createEllipseControls(newCanvas, event.target); // Assuming createEllipseControls is a function
                }
              }
            };
            function storeInitialPathData() {
              const selectedObjects = canvas.getActiveObjects();
              selectedObjects.forEach(obj => {
                if (obj.type === 'path' && !obj.initialPathData) {
                  // Store the initial path data in a custom property
                  obj.initialPathData = fabricPathToSVG(obj);

                }
              });
            }
            newCanvas.on('selection:created', storeInitialPathData);
            newCanvas.on('selection:updated', storeInitialPathData);

            newCanvas.on('object:added', () => { handleCanvasChange(index) });
            newCanvas.on('object:modified', (e) => { handleCanvasChange(index); });
            newCanvas.on('object:removed', () => handleCanvasChange(index));
            newCanvas.on('mouse:down', handleMouseDown);
            newCanvas.on('mouse:move', handleMouseMove);
            newCanvas.on('mouse:up', handleMouseUp);
            newCanvas.on('object:scaling', function (e) {
              const obj = e.target;
              console.log(`object:scaling: Scaling X: ${obj.scaleX}, Scaling Y: ${obj.scaleY}, Transformation Matrix:`, obj.calcTransformMatrix());
            });
            newCanvas.on('object:rotating', function (e) {
              const obj = e.target;
              console.log(`object:rotating: Rotating X: ${obj.left}, Rotating Y: ${obj.top}, angle: ${obj.angle}`, obj);
            });
            newCanvas.on('object:skewing', (e) => {
              console.log("fabricToPaperPath: object:skewing: Canvas.getActiveObject() :", e.target.skewX, newCanvas.getActiveObject())
            });
            function addControlPointsToSelectedObjects(selectedObjects) {
              selectedObjects.forEach((obj) => {
                if (obj.class === 'textBox') {
                  return;
                }
                const center = obj.getCenterPoint();
                if (obj.group) {
                  const group = obj.group.getCenterPoint();
                  // Create or update the control point and text
                  center.x += group.x;
                  center.y += group.y;
                }
                const controlPoint = new fabric.Circle({
                  left: center.x - 5,
                  top: center.y - 5,
                  radius: 5,
                  fill: 'lightblue',
                  stroke: 'darkblue',
                  selectable: false,
                  evented: false,
                  visible: isVisibleCenter,
                });

                const text = new fabric.Text(`${Math.round(center.x)}, ${Math.round(center.y)}`, {
                  left: center.x - 5,
                  top: center.y - 25,
                  fontSize: 12,
                  fill: 'black',
                  selectable: false,
                  evented: false,
                  visible: isVisibleCenter,
                });

                controlPoint.associatedObject = obj;
                text.associatedObject = obj;

                obj.controlPoint = controlPoint;
                obj.controlText = text;

                newCanvas.add(controlPoint);
                newCanvas.add(text);
              });
            }



            function removeControlPointsFromSelectedObjects(selectedObjects) {
              if (selectedObjects) {
                selectedObjects.forEach((obj) => {
                  if (obj.controlPoint) {
                    newCanvas.remove(obj.controlPoint);
                    newCanvas.remove(obj.controlText);
                    delete obj.controlPoint;
                    delete obj.controlText;
                  }
                });
              }
            }

            newCanvas.on('selection:cleared', (e) => {
              removeControlPointsFromSelectedObjects(e.deselected);
              setActiveObj(null);
              setObjeGuideHeight(null);
              setObjeGuideWidth(null);
            });
            function updateControlPoints(selectedObjects) {
              selectedObjects.forEach((obj) => {

                if (obj.controlPoint) {
                  const center = obj.getCenterPoint();
                  if (obj.group) {
                    const group = obj.group.getCenterPoint();
                    center.x += group.x;
                    center.y += group.y;
                  }
                  obj.controlPoint.set({ left: center.x - 5, top: center.y - 5 });
                  obj.controlText.set({ left: center.x - 5, top: center.y - 25, text: `${Math.round(center.x)}, ${Math.round(center.y)}` });
                  obj.controlPoint.setCoords();
                  obj.controlText.setCoords();
                }
              });
            }

            newCanvas.on('object:moving', (e) => {
              console.log("Object:Moving: e.target ", e.target)
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:scaling', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:rotating', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:skewing', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });


            // newCanvas.on('after:render', function() {
            //   newCanvas.contextContainer.strokeStyle = '#555';

            //   newCanvas.forEachObject(function(obj) {
            //     if(obj.class==='textBox'){
            //     var bound = obj.getBoundingRect();

            //     newCanvas.contextContainer.strokeRect(
            //       bound.left + 0.5,
            //       bound.top + 0.5,
            //       bound.width,
            //       bound.height
            //     );
            //     }
            //   })
            // })
            newCanvas.on('selection:created', (e) => {
              handleSelectionCreated(e);
              handleObjectSelected(e);
              addControlPointsToSelectedObjects(e.selected);
            });

            newCanvas.on('selection:updated', (e) => {
              removeControlPointsFromSelectedObjects(e.deselected);
              addControlPointsToSelectedObjects(e.selected);
            });

            newCanvas.on('selection:created', (e) => {
              console.log("handleSelectionCreated , Canvas.getActiveObject() :", e, newCanvas.getActiveObject())
            });

            newCanvas.on('path:created', handlePathCreated);
            newCanvas.on('selection:updated', handleSelectionUpdated);
            newCanvas.on('mouse:dblclick', handleMouseDoubleClick);

          }
        } else {
          console.log(`Removing event listeners: from Canvas ${index + 1}`);
          newCanvas.__eventListenersAttached = false;

          // Remove all event listeners here
          newCanvas.off();
        }
      })
    }
  }, [canvases, activeCanvasIndex])
  useEffect(() => {
    if (canvas) {
      const canvasElement = canvasRef.current;

      canvasElement.addEventListener('contextmenu', function (event) {
        event.preventDefault(); // This will block the right-click context menu
        return false; // Some browsers need this to ensure the event is fully handled
      });
      console.log('canvasElement: ', canvasElement)
    }
  }, [canvas])
  useEffect(() => {
    const newCanvas = createNewCanvas(canvasRef.current, bg, size, 0);
    setCanvas(newCanvas);
    setCanvases([newCanvas]);
    setActiveCanvas(0);

    return () => {
      newCanvas.dispose();
    };

  }, []);
  useEffect(() => {
    console.log(`canvas at any time: ${activeCanvasIndex}:`, activeCanvasIndex, canvases);
  }, [activeCanvasIndex]);
  const switchCanvas = (index) => {
    console.log("Canvas Switch: in switchCanvas just before switching:", canvas, canvases)
    setActiveCanvasIndex(index);

    canvases.forEach((canvas, i) => {
      const canvasElement = document.getElementById(`canvas${i}`);
      console.log("Switch: in forEach: canvasElement: ", canvasElement);

      if (i === index) {
        canvasElement.style.display = 'block';
        canvasElement.parentElement.style.display = 'block';
        setCanvas(canvas);
        console.log("Canvas Switch: in forEach: activeCanvas: ", index, canvas);
      } else {
        canvasElement.style.display = 'none';
        canvasElement.parentElement.style.display = 'none';

        console.log("Canvas Switch: in forEach: inactiveCanvas: ", index, canvas);

      }
    });
    console.log("Canvas Switch: final:", index, canvas, activeCanvasIndex);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isFullscreen) {
        if (event.key === 'ArrowRight') {
          setActiveCanvasIndex((prevIndex) => (prevIndex + 1) % canvases.length);
        } else if (event.key === 'ArrowLeft') {
          setActiveCanvasIndex((prevIndex) => (prevIndex - 1 + canvases.length) % canvases.length);
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullscreen, canvases.length]);
  useEffect(() => {
    switchCanvas(activeCanvasIndex);
  }, [activeCanvasIndex]);
    
  
  useEffect(() => {
    if (!canvas) return;

    console.log("Canvas Switch: event listeners: if any change is made to the canvas:", activeCanvasIndex, canvases, canvas);

    try {
      canvas.renderAll();
    } catch (error) {
      console.error("Error rendering canvas:", error);
    }


  }, [activeCanvasIndex, canvases]);

  useEffect(() => {
    if (canvas) {
      let activeObject = canvas.getActiveObject();
      if (activeObject && custControls) {
        if (activeObject.controlPoint) {
          canvas.remove(activeObject.controlPoint);
          canvas.remove(activeObject.controlText);
          delete activeObject.controlPoint;
          delete activeObject.controlText;
        } else if (activeObject._objects) {
          activeObject._objects.forEach((obj) => {
            if (obj.controlPoint) {
              canvas.remove(obj.controlPoint);
              canvas.remove(obj.controlText);
              delete obj.controlPoint;
              delete obj.controlText;
            }
          })
        }
      }
    }
  }, [custControls])
  const addCustomControls = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const defaultControlNames = ['tl', 'tr', 'bl', 'br', 'mt', 'mb', 'ml', 'mr', 'mtr'];
      if (!custControls) {
        defaultControlNames.forEach(control => {
          activeObject.setControlVisible(control, false);
        });
        fabric.Object.prototype.set({
          transparentCorners: false,
          borderColor: 'gray',
          padding: 10,
        });
      } else {
        defaultControlNames.forEach(control => {
          activeObject.setControlVisible(control, true);

        });
        fabric.Object.prototype.set({
          transparentCorners: false,
          borderColor: 'blue',
          padding: 0,
        });

      }

      // Add custom controls
      activeObject.setControlVisible('customRotationControls', !custControls);
      activeObject.setControlVisible('customRotationControls1', !custControls);
      activeObject.setControlVisible('customRotationControls2', !custControls);
      activeObject.setControlVisible('customRotationControls3', !custControls);
      activeObject.setControlVisible('customRotationControlsCenter', !custControls);
      activeObject.setControlVisible('customSkewControls', !custControls);
      activeObject.setControlVisible('customSkewControls1', !custControls);
      activeObject.setControlVisible('customSkewControls2', !custControls);
      activeObject.setControlVisible('customSkewControls3', !custControls);


      setCustControls(!custControls);
      canvas.requestRenderAll();
    }
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const [objGuideWidth, setObjeGuideWidth] = useState(null);
  const [objGuideHeight, setObjeGuideHeight] = useState(null);
  const handleObjectSelected = (event) => {
    setAnchorEl(canvasRef.current);

    var obj = event.selected;

    if (obj[0].class === 'circle' && obj[0].circlePathInstance) {
      const circlePath = obj[0].circlePathInstance;

      obj[0].setStartAngle = (angle) => {
        circlePath.setStartAngle(angle);
      };

      obj[0].setEndAngle = (angle) => {
        circlePath.setEndAngle(angle);
      };

      obj[0].setRadius = (radius) => {
        circlePath.setRadius(radius);
      };

      obj[0].setAngleType = (type) => {
        circlePath.setAngleType(type);
      };
    }

    if (obj[0].class == "star" && !obj[0].spokeRatio) {
      handleSpokeRatioChange(0.4);
      obj[0].set({ spokeRatio: 0.4 });
    }

    if (!obj[0].fill) {
      obj[0].set({ fill: 'transparent' });
    }
    if (!obj[0].unit) {
      obj[0].set({ unit: 'px' });
    }
    console.log("handleObjectSelected:", obj, obj[0]);

    setActiveObj(obj);
    if(obj[0]?.class === 'chart'){
      const initialColors = obj[0].chart.data.datasets[0].backgroundColor;
      const initialData = obj[0].chart.data.datasets[0].data;

      setColors(initialColors);   
      setDatasetData(initialData);
      console.log('handleColorChange: initial setCOlor ', initialColors);       
    }
   
  
  };
  useEffect(()=>{
    if(canvas){
      updateGuideRanges(showRanges);
      const activeObject = canvas.getActiveObject();

      activeObject?.on('moving', () => updateGuideRanges(showRanges));
      activeObject?.on('scaling', () => updateGuideRanges(showRanges));
      activeObject?.on('selected', () => updateGuideRanges(showRanges));

      // obj[0].on('rotating', () => updateGuideRanges(obj[0]));
      activeObject?.on('modified', () => updateGuideRanges(showRanges));
    }
  },[showRanges,canvas?.getActiveObject()])
  useEffect(() => { console.log('handleCanvasClick: sprayObj:', sprayObj) }, [sprayObj])
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [areaOfSpray, setAreaOfSpray] = useState(10);
  const numberOfCopiesRef = useRef(numberOfCopies);  // Create a ref to hold the latest value
  const areaOfSprayRef = useRef(areaOfSpray);  
  const sprayCircleRef = useRef(null);  

  const handleMouseMove = (options) => {
    const pointer = canvas.getPointer(options.e);
    const radius = areaOfSprayRef.current; 
    if(spraySelectRef.current){
    if (!sprayCircleRef.current) {
      sprayCircleRef.current = new fabric.Circle({
        radius: radius,
        left: pointer.x,
        originX:'center',
        originY:'center',
        top: pointer.y,
        fill: 'rgba(0, 0, 0, 0.1)',  // Light fill to show the area of spray
        stroke: 'black',             // Black stroke to outline the circle
        strokeWidth: 1,
        selectable: false,           // Make the circle non-selectable
        evented: false               // Disable event handling for the circle
      });
  
      canvas.add(sprayCircleRef.current);
    } else {
      // If the circle exists, just update its position
      sprayCircleRef.current.set({ left: pointer.x, top: pointer.y });
    }
  }

    canvas.renderAll();
  };
  
  const handleMouseOut = () => {
    if (sprayCircleRef.current) {
      canvas.remove(sprayCircleRef.current);
      sprayCircleRef.current = null;
      canvas.renderAll();
    }
  };
  
  
  // Sync ref with state value when it changes
  useEffect(() => {
    numberOfCopiesRef.current = numberOfCopies;
    areaOfSprayRef.current = areaOfSpray;
    if(canvas){
      canvas.on('mouse:move', handleMouseMove);
      canvas.on('mouse:out', handleMouseOut);      
    }
  }, [numberOfCopies,areaOfSpray,canvas]);
  
  const handleCanvasClick = (options) => {
    if (spraySelectRef.current && sprayObjRef.current) {
      console.log('handleCanvasClick: spraySelect and sprayObj both true', options, numberOfCopiesRef.current);
  
      const pointer = canvas.getPointer(options.e);
      const radius = areaOfSprayRef.current;  
      for (let i = 0; i < numberOfCopiesRef.current; i++) {
        const angle = Math.random() * 2 * Math.PI;  
        const distance = Math.random() * radius;     
        const offsetX = Math.cos(angle) * distance;
        const offsetY = Math.sin(angle) * distance;
  
        const newObject = fabric.util.object.clone(sprayObjRef.current);
  
        newObject.set({ left: pointer.x + offsetX, top: pointer.y + offsetY });
  
        console.log(`handleCanvasClick: newObject #${i + 1}:`, newObject);
        canvas.add(newObject);
      }
      canvas.renderAll();
    }
  };
  
  

  const updateGuideRanges = (show) => {
    // Early return if show is false
    if (!show) {
      setObjeGuideHeight(null);
      setObjeGuideWidth(null);

        return;
    }

    // Get the active object(s) from the canvas
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
        console.log("No active object found");
        return;
    }

    // Handle single object or group of objects
    let objectLeft, objectTop, objectWidth, objectHeight;

    if (activeObject.type === 'group') {
        // For groups, use the bounding box of the group
        const groupBounds = activeObject.getBoundingRect();
        objectLeft = groupBounds.left;
        objectTop = groupBounds.top;
        objectWidth = groupBounds.width;
        objectHeight = groupBounds.height;
    } else {
        // For single objects, use its properties
        objectLeft = activeObject.left;
        objectTop = activeObject.top;
        objectWidth = activeObject.width * activeObject.scaleX;
        objectHeight = activeObject.height * activeObject.scaleY;
    }

    // Set the ranges
    const rangeStart = objectLeft;
    const rangeEnd = objectLeft + objectWidth;
    const rangeStartHeight = objectTop;
    const rangeEndHeight = objectTop + objectHeight;

    setObjeGuideWidth([[rangeStart, rangeEnd]]);
    setObjeGuideHeight([[rangeStartHeight, rangeEndHeight]]);

    console.log("updateGuideRanges: rangeStart, rangeEnd, objeGuideWidth:", rangeStart, rangeEnd);
    console.log("updateGuideRanges: rangeStartHeight, rangeEndHeight, objeGuideHeight:", rangeStartHeight, rangeEndHeight);
};




  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handlePropertyChange = (property, value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];

      if (property === 'width' && obj.width !== value) {
        let newWidth = value * conversionRates[activeObj[0].unit]
        const scaleX = newWidth / obj.width;
        obj.scaleX *= scaleX;
        obj.width = newWidth;
        // obj.left -= (value * conversionRates[activeObj[0].unit] - obj.width) / 2;
        console.log('handlePropertyChange: width change: ', obj, obj.width, value, obj.left)
      }
      else if (property === 'unit' && obj.unit !== value) {
        console.log("handlePropertyChange: Handle Property unit : ", obj, obj.width);

        // const widthInPixels = obj.width * conversionRates[obj.unit];
        // const heightInPixels = obj.height * conversionRates[obj.unit];
        // console.log("handlePropertyChange: widthInPixels heightInPixels",conversionRates[obj.unit], widthInPixels, heightInPixels)

        // obj.width = widthInPixels / conversionRates[value]; 
        // obj.height = heightInPixels / conversionRates[value]; 
        // console.log("handlePropertyChange: Handle Property Change unit : ", obj.width,obj.height,widthInPixels,heightInPixels); 
        obj.unit = value;
      }

      else if (property === 'height' && obj.height !== value) {
        let newHeight = value * conversionRates[activeObj[0].unit]

        const scaleY = newHeight / obj.height;
        obj.scaleY *= scaleY;
        obj.height = newHeight;
        // obj.top -= (value * conversionRates[activeObj[0].unit] - obj.height) / 2;
      } else if (property === 'cornerRadiusX' && obj.type === 'rect') {
        obj.set({ rx: value })
      } else if (property === 'cornerRadiusY' && obj.type === 'rect') {
        obj.set({ ry: value });
      } else if (property === "radius") {
        obj.set(property, value);
        obj.setRadius(value);
      } else {
        obj.set(property, value);
      }

      if (property === "angle") {
        obj.set({ centeredRotation: true });
      }


      obj.setCoords();    
      canvas.renderAll(); 
      setActiveObj([obj]); 
    }
  };
  const handlePositionChange = (property, value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (property === "strokeWidth") {
        let newStroke = value * conversionRates[activeObj[0].unit];
        obj.set({ strokeWidth: newStroke });
      }
      obj.set(property, value);


      obj.setCoords();
      setActiveObj([obj]);
      canvas.renderAll();
    }
  };

  const updateStarVertices = (polygon, newCorners) => {
    const radius = polygon.width / 2;
    const innerRadius = radius / 2;
    const centerX = polygon.left + radius;
    const centerY = polygon.top + radius;

    const vertices = [];
    for (let i = 0; i < newCorners * 2; i++) {
      const angle = (i * Math.PI) / newCorners;
      const length = i % 2 === 0 ? radius : innerRadius;
      vertices.push({
        x: centerX + length * Math.sin(angle),
        y: centerY - length * Math.cos(angle),
      });
    }

    // Update points in the polygon
    polygon.points = vertices;
    polygon.width = radius * 2;
    polygon.height = radius * 2;

    canvas.renderAll();
  };
  const [sentFromCorner, setSentFromCorner] = useState(false);
  const [sentFromSpoke, setSentFromSpoke] = useState(false);

  const polygonToPath = (polygon) => {
    const points = polygon.points || [];
    let pathData = '';

    if (points.length > 0) {
      pathData += `M ${points[0].x} ${points[0].y} `;
      for (let i = 1; i < points.length; i++) {
        pathData += `L ${points[i].x} ${points[i].y} `;
      }
      pathData += 'Z'; // Close the path
    }

    return pathData.trim();
  };


  const pathToPolygon = (pathData) => {
    const points = [];
    const commands = pathData.match(/[MLC][^MLC]+/g) || [];

    let lastPoint = null;

    commands.forEach(command => {
      const type = command[0];
      const coords = command.slice(1).trim().split(/[\s,]+/).map(Number);

      if (type === 'M' || type === 'L') {
        // Move or line to
        for (let i = 0; i < coords.length; i += 2) {
          const point = { x: coords[i], y: coords[i + 1] };
          points.push(point);
          lastPoint = point;
        }
      } else if (type === 'C') {
        // Approximate cubic Bezier curve (simplified here)
        for (let i = 0; i < coords.length; i += 6) {
          const endPoint = { x: coords[i + 4], y: coords[i + 5] };
          points.push(endPoint);
        }
      } else if (type === 'A') {
        // Approximate arc (simplified here)
        const endPoint = { x: coords[5], y: coords[6] };
        points.push(endPoint);
      }
    });

    // Ensure points form a closed loop
    if (points.length > 1 && points[0].x === points[points.length - 1].x && points[0].y === points[points.length - 1].y) {
      points.pop();
    }

    return points;
  };

  const handleStarCornersChange = (value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const newCorners = parseInt(value, 10);
        if (newCorners >= 2) {
          // Convert path to polygon
          const polygonPoints = pathToPolygon(obj.initialPathData);
          console.log("handleStarCornerChange: polygonPoints: ", polygonPoints, obj.path, obj.path.join(' '));
          const polygon = new fabric.Polygon(polygonPoints);
          console.log("handleStarCornerChange: polygon: ", polygon);

          // Update the polygon vertices
          updateStarVertices(polygon, newCorners);
          if (obj.spokeRatio !== 0.4) {
            updateStarSpokeRatio(polygon, obj.spokeRatio);
          }
          // Convert polygon back to path
          const newPathString = polygonToPath(polygon);
          let newPath = svgPathToFabric(newPathString);
          console.log("handleStarCornerChange: newPath: ", newPath, newPathString);
          if (obj.cornerRadius && obj.cornerRadius !== 0) {
            const roundedSVGPath = roundPathCorners(newPathString, factoral ? cornerRadius / 10 : 10 * cornerRadius, factoral);
            newPath = svgPathToFabric(roundedSVGPath);
            console.log("handleStarCornerChange: newPath: if cornerRadius", newPath, roundedSVGPath);

          }

          const currentLeft = obj.left;
          const currentTop = obj.top;

          const boundingBox = calculateBoundingBox(polygon.points);
          obj.set({
            width: boundingBox.width,
            height: boundingBox.height,
            path: newPath.path,
            left: currentLeft, top: currentTop, corners: newCorners, cornerRadius
          });

          obj.setCoords(); // Ensure coordinates are updated
          obj.dirty = true; // Mark as dirty to trigger redraw
          setActiveObj([obj]);
          console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);
          canvas.renderAll();
        }
      }
    }
  };


  const updateStarSpokeRatio = (star, spokeRatio) => {
    const radius = star.width / 2;
    const innerRadius = radius * spokeRatio;
    const centerX = star.left + radius;
    const centerY = star.top + radius;
    const numPoints = star.points.length; // Total number of points (both outer and inner)


    const vertices = [];
    for (let i = 0; i < numPoints; i++) {
      const angle = (i * Math.PI * 2) / numPoints; // Full circle divided by total number of points
      const length = i % 2 === 0 ? radius : innerRadius; // Alternate between outer and inner radius
      vertices.push({
        x: centerX + length * Math.sin(angle),
        y: centerY - length * Math.cos(angle),
      });
    }

    star.set({ points: vertices });
    star.setCoords();
    canvas.renderAll();
  };
  const calculateBoundingBox = (path) => {
    const boundingBox = {
      left: Infinity,
      top: Infinity,
      right: -Infinity,
      bottom: -Infinity
    };

    path.forEach(point => {
      if (point.x < boundingBox.left) boundingBox.left = point.x;
      if (point.y < boundingBox.top) boundingBox.top = point.y;
      if (point.x > boundingBox.right) boundingBox.right = point.x;
      if (point.y > boundingBox.bottom) boundingBox.bottom = point.y;
    });
    console.log("calculateBoundingBox: ", boundingBox)
    return {
      left: boundingBox.left,
      top: boundingBox.top,
      width: boundingBox.right - boundingBox.left,
      height: boundingBox.bottom - boundingBox.top
    };
  };

  const handleSpokeRatioChange = (value, sentFromCorner = false) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const spokeRatio = value; // Ensure value is between 0 and 1
        const polygonPoints = pathToPolygon(obj.initialPathData);
        const polygon = new fabric.Polygon(polygonPoints);

        const { left, top, cornerRadius } = obj;
        if (obj.corners !== 5) {
          updateStarVertices(polygon, obj.corners);
        }

        // Update vertices
        updateStarSpokeRatio(polygon, spokeRatio);
        const newPathString = polygonToPath(polygon);
        let newPath = svgPathToFabric(newPathString);
        if (obj.cornerRadius !== 0) {
          const roundedSVGPath = roundPathCorners(newPathString, factoral ? cornerRadius / 10 : 10 * cornerRadius, factoral);
          newPath = svgPathToFabric(roundedSVGPath);
        }
        // Restore properties
        const boundingBox = calculateBoundingBox(polygon.points);
        obj.set({
          width: boundingBox.width,
          height: boundingBox.height,
          path: newPath.path, left, top, spokeRatio, cornerRadius
        });

        obj.setCoords(); // Ensure coordinates are updated
        obj.dirty = true; // Mark as dirty to trigger redraw


        setActiveObj([obj]);
        console.log("star props: ", boundingBox, obj.spokeRatio, obj.cornerRadius, obj.corners);
        canvas.renderAll();
      }
    }
  };

  const updatePolygonVertices = (polygon, corners) => {
    const radius = 50; // Fixed radius for simplicity
    const centerX = polygon.left;
    const centerY = polygon.top;

    const vertices = [];
    for (let i = 0; i < corners; i++) {
      const angle = (i * Math.PI * 2) / corners;
      vertices.push({
        x: 0.5 * centerX + radius * Math.sin(angle),
        y: 0.5 * centerY - radius * Math.cos(angle),
      });
    }

    polygon.set({ points: vertices });
    polygon.setCoords();
    canvas.renderAll();
  };



  const handlePolygonCornersChange = (value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const newCorners = parseInt(value, 10);
        if (newCorners >= 2) {
          // Convert path to polygon
          const polygonPoints = pathToPolygon(obj.initialPathData);
          console.log("handlePolygonCornerChange: polygonPoints: ", polygonPoints, obj.path, obj.path.join(' '));
          const polygon = new fabric.Polygon(polygonPoints);
          console.log("handlePolygonCornerChange: polygon: ", polygon);

          // Update the polygon vertices
          updatePolygonVertices(polygon, newCorners);
          if (obj.spokeRatio !== 0.4) {
            updateStarSpokeRatio(polygon, obj.spokeRatio);
          }
          // Convert polygon back to path
          const newPathString = polygonToPath(polygon);
          let newPath = svgPathToFabric(newPathString);
          console.log("handlePolygonCornerChange: newPath: ", newPath, newPathString);
          if (obj.cornerRadius && obj.cornerRadius !== 0) {
            const roundedSVGPath = roundPathCorners(newPathString, cornerRadius, true);
            newPath = svgPathToFabric(roundedSVGPath);
            console.log("handlePolygonCornerChange: newPath: if cornerRadius", newPath, roundedSVGPath, obj.cornerRadius);

          }

          // Save the position
          const currentLeft = obj.left;
          const currentTop = obj.top;

          // Update the path object
          obj.set({ path: newPath.path, left: currentLeft, top: currentTop, corners: value });
          obj.setCoords(); // Ensure coordinates are updated for the new position
          setActiveObj([obj]);
          canvas.calcOffset();
          console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);

          canvas.renderAll();
        }
      }
    }
  };

  const handleDeleteObject = () => {
    if (activeObj && canvas) {
        if (activeObj.length > 1) {
            activeObj.forEach(obj => {
                if (obj.controlPoint) {
                    canvas.remove(obj.controlPoint);
                    canvas.remove(obj.controlText);
                    delete obj.controlPoint;
                    delete obj.controlText;
                }
                canvas.remove(obj);
            });
        } else {
            const obj = activeObj[0];
            if (obj.controlPoint) {
                canvas.remove(obj.controlPoint);
                canvas.remove(obj.controlText);
                delete obj.controlPoint;
                delete obj.controlText;
            }
            canvas.remove(obj);
        }
        setActiveObj(null);
        setObjeGuideHeight(null);
        setObjeGuideWidth(null);
        handleClose();
        canvas.renderAll();
    }
};

  const [angleType, setAngleType] = useState("");

  const [startAngle, setStartAngle] = useState(0);
  const [endAngle, setEndAngle] = useState(360);

  const handleStartAngleChange = (e) => {
    const value = parseFloat(e.target.value);
    setStartAngle(value);
    console.log("angleChange: Start angle changed:", value, activeObj[0]);

    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setStartAngle(value);
      setActiveObj({ ...activeObj });
      console.log("angleChange: Start angle changed:", value);
    }
  };

  const handleEndAngleChange = (e) => {
    const value = parseFloat(e.target.value);
    setEndAngle(value);
    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setEndAngle(value);
      setActiveObj({ ...activeObj });
      console.log("angleChange: End angle changed:", value);
    }
  };


  const handleAngleTypeChange = (e) => {
    const value = e.target.value;
    setAngleType(value);
    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setAngleType(value);
      setActiveObj({ ...activeObj });
      console.log("Angle type changed:", value);
    }
  };






  const [cornerRadius, setCornerRadius] = useState(0); // Initial cornerRadius


  function roundPathCorners(pathString, radius, useFractionalRadius) {
    function moveTowardsLength(movingPoint, targetPoint, amount) {
      var width = (targetPoint.x - movingPoint.x);
      var height = (targetPoint.y - movingPoint.y);

      var distance = Math.sqrt(width * width + height * height);

      return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
    }
    function moveTowardsFractional(movingPoint, targetPoint, fraction) {
      return {
        x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
        y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction
      };
    }

    // Adjusts the ending position of a command
    function adjustCommand(cmd, newPoint) {
      if (cmd.length > 2) {
        cmd[cmd.length - 2] = newPoint.x;
        cmd[cmd.length - 1] = newPoint.y;
      }
    }

    // Gives an {x, y} object for a command's ending position
    function pointForCommand(cmd) {
      return {
        x: parseFloat(cmd[cmd.length - 2]),
        y: parseFloat(cmd[cmd.length - 1]),
      };
    }

    // Split apart the path, handing concatonated letters and numbers
    var pathParts = pathString
      .split(/[,\s]/)
      .reduce(function (parts, part) {
        var match = part.match("([a-zA-Z])(.+)");
        if (match) {
          parts.push(match[1]);
          parts.push(match[2]);
        } else {
          parts.push(part);
        }

        return parts;
      }, []);

    // Group the commands with their arguments for easier handling
    var commands = pathParts.reduce(function (commands, part) {
      if (parseFloat(part) == part && commands.length) {
        commands[commands.length - 1].push(part);
      } else {
        commands.push([part]);
      }

      return commands;
    }, []);

    // The resulting commands, also grouped
    var resultCommands = [];

    if (commands.length > 1) {
      var startPoint = pointForCommand(commands[0]);

      // Handle the close path case with a "virtual" closing line
      var virtualCloseLine = null;
      if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
        virtualCloseLine = ["L", startPoint.x, startPoint.y];
        commands[commands.length - 1] = virtualCloseLine;
      }

      // We always use the first command (but it may be mutated)
      resultCommands.push(commands[0]);

      for (var cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
        var prevCmd = resultCommands[resultCommands.length - 1];

        var curCmd = commands[cmdIndex];

        // Handle closing case
        var nextCmd = (curCmd == virtualCloseLine)
          ? commands[1]
          : commands[cmdIndex + 1];

        // Nasty logic to decide if this path is a candidite.
        if (nextCmd && prevCmd && (prevCmd.length > 2) && curCmd[0] == "L" && nextCmd.length > 2 && nextCmd[0] == "L") {
          // Calc the points we're dealing with
          var prevPoint = pointForCommand(prevCmd);
          var curPoint = pointForCommand(curCmd);
          var nextPoint = pointForCommand(nextCmd);

          // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
          var curveStart, curveEnd;

          if (useFractionalRadius) {
            curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius);
            curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius);
          } else {
            curveStart = moveTowardsLength(curPoint, prevPoint, radius);
            curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
          }

          // Adjust the current command and add it
          adjustCommand(curCmd, curveStart);
          curCmd.origPoint = curPoint;
          resultCommands.push(curCmd);

          // The curve control points are halfway between the start/end of the curve and
          // the original point
          var startControl = moveTowardsFractional(curveStart, curPoint, .5);
          var endControl = moveTowardsFractional(curPoint, curveEnd, .5);

          // Create the curve 
          var curveCmd = ["C", startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y];
          // Save the original point for fractional calculations
          curveCmd.origPoint = curPoint;
          resultCommands.push(curveCmd);
        } else {
          // Pass through commands that don't qualify
          resultCommands.push(curCmd);
        }
      }

      // Fix up the starting point and restore the close path if the path was orignally closed
      if (virtualCloseLine) {
        var newStartPoint = pointForCommand(resultCommands[resultCommands.length - 1]);
        resultCommands.push(["Z"]);
        adjustCommand(resultCommands[0], newStartPoint);
      }
    } else {
      resultCommands = commands;
    }

    return resultCommands.reduce(function (str, c) { return str + c.join(" ") + " "; }, "");
  }


  const [factoral, setFactoral] = useState(false);

  function fabricPathToSVG(fabricPath) {
    // Get the full SVG string from the Fabric.js path object
    const svgString = fabricPath.toSVG();

    // Create a temporary SVG element to parse the SVG string
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

    // Extract the 'd' attribute from the first path element
    const pathElement = svgDoc.querySelector('path');
    return pathElement ? pathElement.getAttribute('d') : '';
  }


  function svgPathToFabric(svgPath) {
    console.log('svgPathToFabric: ', svgPath)
    return new fabric.Path(svgPath);
  }









  const handleChange = (event, newValue) => {
    setCornerRadius(newValue);
    updateSelectedObjectRoundness(newValue);
  };

  function updateSelectedObjectRoundness(roundness) {
    const selectedObjects = canvas.getActiveObjects();

    selectedObjects.forEach(obj => {
      if (obj.type === 'path') {
        if (!obj.initialPathData) {
          console.error("Initial path data not found for object");
          return;
        }
        const initialSVGPath = obj.initialPathData;
        let newPath = initialSVGPath;
        if (obj.class === "star" && obj.spokeRatio && obj.spokeRatio !== 0.4) {
          const polygonPoints = pathToPolygon(newPath);
          const polygon = new fabric.Polygon(polygonPoints);
          updateStarSpokeRatio(polygon, obj.spokeRatio);
          const newPathString = polygonToPath(polygon);
          newPath = newPathString;
          console.log("updateSelectedObjectRoundness: if spokeRatio: initialSVGPath, newPath:", initialSVGPath, newPath);
        }
        if (obj.class === "star" && obj.corners && obj.corners !== 5) {
          const polygonPoints = pathToPolygon(newPath);
          const polygon = new fabric.Polygon(polygonPoints);
          updateStarVertices(polygon, obj.corners);
          const newPathString = polygonToPath(polygon);
          newPath = newPathString;
          console.log("updateSelectedObjectRoundness: if corners: initialSVGPath, newPath:", initialSVGPath, newPath);
        }

        const roundedSVGPath = roundPathCorners(newPath, factoral ? roundness / 10 : 10 * roundness, factoral);
        console.log("updateSelectedObjectRoundness: initialSVGPath: ", newPath);
        console.log("updateSelectedObjectRoundness: roundedSVGPath: ", roundedSVGPath);


        newPath = svgPathToFabric(roundedSVGPath);
        console.log("updateSelectedObjectRoundness: newPath: ", newPath.path);


        obj.set({ path: newPath.path, cornerRadius: roundness });
        obj.dirty = true;
        console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);
        canvas.renderAll();
      }


    });
  }


  const handlePolygonDistortionChange = (value) => {
    if (activeObj && canvas) {
      const poly = activeObj[0];
      const polygonPoints = pathToPolygon(poly.initialPathData);
      const obj = new fabric.Polygon(polygonPoints);
      console.log("handlePolygonDistortionChange: ", poly, polygonPoints, obj);

      if (obj.type === 'polygon') {
        const distortionFactor = parseFloat(value);
        if (!isNaN(distortionFactor)) {
          const vertices = [];
          const maxDistortion = distortionFactor; // Maximum distortion distance

          for (let i = 0; i < obj.points.length; i++) {
            const point = obj.points[i];

            // Apply random distortion
            const randomAngle = Math.random() * 25 * Math.PI; // Random angle in radians
            const randomRadius = Math.random() * 25 * maxDistortion; // Random radius within maxDistortion

            // Distortion vector components
            const distortionX = randomRadius * Math.cos(randomAngle);
            const distortionY = randomRadius * Math.sin(randomAngle);

            vertices.push({
              x: point.x + 2 * distortionX,
              y: point.y + 2 * distortionY,
            });
          }

          obj.set({ points: vertices });
          obj.setCoords(); // Update internal coordinates for accurate bounding box

          const newPathString = polygonToPath(obj);
          let newPath = svgPathToFabric(newPathString);
          poly.set({ path: newPath.path, dirty: true });

          canvas.calcOffset(); // Recalculate canvas offset
          canvas.renderAll(); // Render canvas to apply changes

          setActiveObj([poly]);
          console.log("handlePolygonDistortionChange: ", poly, poly.path);
        }
      }
    }
  };
  const [lineStyle, setLineStyle] = useState('solid');

  const handleChangeLine = (event) => {
    setLineStyle(event.target.value);
    updateLineStyle(event.target.value);
  };

  const updateLineStyle = (style) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'line') {
      activeObject.set({ strokeDashArray: getDashArray(style) });
      canvas.renderAll();
    } else if (activeObject && activeObject.type === 'path') {
      activeObject.set({ strokeDashArray: getDashArray(style) });
      canvas.renderAll();
    }
  };

  const getDashArray = (style) => {
    switch (style) {
      case 'dotted':
        return [1, 2];
      case 'dashed':
        return [10, 5];
      case 'dashed-dotted':
        return [10, 5, 1, 5];
      case 'solid':
      default:
        return [];
    }
  };

  const [containerScrollDimensions, setContainerScrollDimensions] = useState({
    height: 0, width: 0
  });
  useEffect(() => {
    if (document.getElementById("canvas-container")) {
      setContainerScrollDimensions({
        height: document.getElementById("canvas-container").scrollHeight,
        width: document.getElementById("canvas-container").scrollWidth
      })
    }
  }, [document.getElementById("canvas-container")])
  const [lockGuides, setLockGuides] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl1(null);
  };

  const handleToggleLock = (option) => {
    if (lockGuides.includes(option)) {
      const newLockGuides = lockGuides.filter((item) => item !== option);
      setLockGuides(newLockGuides);

    } else {
      if (option === 'remove') {
        horizonalGuidesRef.current.setState({
          guides: []
        });
        verticalGuidesRef.current.setState({
          guides: []
        });
        return;
      }
      setLockGuides([...lockGuides, option]);
    }
    console.log('handleToggleLock: ', option, lockGuides)
    // handleMenuClose();
  };

const handleUnlockAll = () => {
  setLockGuides([]);
  handleMenuClose();
};
const handleGuideClick = (guide) => {
  console.log('Guide clicked:', guide);
};
const handleAddChart = async (chart, chartData) => {
  let newShape = null;
  console.log('handleAddChart: ', chart, chartData);

  switch (chart) {
    case 'barVertical':
      newShape = new fabric.Chart({
        width: 300,
        height: 300,
        class:'chart',
        chart: {
          type: 'bar',
          data: {
            labels: chartData.labels,
            datasets: [
              {
                label: '# of Votes',
                data: chartData.data,
                backgroundColor: chartData.backgroundColor,
              },
            ],
        
          },
          
        },
      });
      console.log('handleAddChart: barVertical', newShape, chartData);
      break;

    case 'barHorizontal':
      newShape = new fabric.Chart({
        width: 200,
        height: 200,
        class: 'chart',
        chart: {
          type: 'bar',
          data: {
            labels: chartData.labels,
            datasets: [
              {
                label: '# of Votes',
                data: [Math.random(), Math.random(), Math.random()],
                backgroundColor: chartData.backgroundColor,
              },
            ],
          },
          options: {
            indexAxis: 'y',
            scales: {
              x: {
                beginAtZero: true,
              },
            },
          },
        },
      });
      console.log('handleAddChart: barVertical', newShape, chartData);

      break;

      case 'line':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'line',
            data: {
              labels: chartData.labels,
              datasets: chartData.datasets.map(dataset => ({
                    ...dataset,
                    backgroundColor: Array.isArray(dataset.backgroundColor) 
                        ? dataset.backgroundColor 
                        : [dataset.backgroundColor ], 
                })),
            },
          },
        });
        break;

      case 'pie':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'pie',
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.data,
                  backgroundColor: chartData.backgroundColor,
                },
              ],
            },
          },
        });
        break;

      case 'doughnut':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'doughnut',
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.data,
                  backgroundColor: chartData.backgroundColor,
                },
              ],
            },
          },
        });
        break;

      case 'scatter':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'scatter',
            data: {
              datasets: chartData.datasets.map(dataset => ({
                    ...dataset,
                    backgroundColor: Array.isArray(dataset.backgroundColor) 
                        ? dataset.backgroundColor 
                        : [dataset.backgroundColor],
                })),
            },
            options: {
              scales: {
                x: {
                  type: 'linear',
                  position: 'bottom',
                },
              },
            },
          },
        });
        break;

      case 'venn':
        // Hardcoded data for the Venn diagram with valid sets and sizes
const chartData1 = [
  { sets: ['A'], size: 10, color: '#f28c28' }, // Single set A
  { sets: ['B'], size: 10, color: '#73c2fb' }, // Single set B
  { sets: ['C'], size: 10, color: '#90ee90' }, // Single set C
  { sets: ['A', 'B'], size: 5, color: '#ff7f50' },  // Intersection of A and B
  { sets: ['A', 'C'], size: 5, color: '#ff6347' },  // Intersection of A and C
  { sets: ['B', 'C'], size: 5, color: '#da70d6' },  // Intersection of B and C
  { sets: ['A', 'B', 'C'], size: 2, color: '#8a2be2' }  // Intersection of A, B, and C
];

const applyColorsWithD3 = (container, sets) => {
  d3.select(container).selectAll('.venn-circle path').each(function (d) {
      const set = sets.find(s => s.sets.join(',') === d.sets.join(','));
      if (set) {
          d3.select(this).style('fill', set.color || '#000000'); // Apply custom color or default to black
      }
  });
};

const drawVennDiagram = (chartData, canvas) => {
  const vennSvg = VennDiagram().width(300).height(300); // Create Venn diagram
  const vennContainer = document.createElement('div');  // Temporary container for Venn diagram
  d3.select(vennContainer).datum(chartData).call(vennSvg); // Draw Venn diagram with the chartData

  applyColorsWithD3(vennContainer, chartData); // Apply colors based on the chartData sets

  // Convert the Venn diagram SVG to a string
  const vennSvgString = new XMLSerializer().serializeToString(vennContainer.querySelector('svg'));

  // Load the SVG string into Fabric.js and render it on the canvas
  fabric.loadSVGFromString(vennSvgString, (objects, options) => {
      if (!objects || objects.length === 0) {
          console.error('No objects were parsed from the SVG string.');
          return;
      }

      try {
          // Group the SVG objects and set their positioning on the canvas
          const svgObjects = fabric.util.groupSVGElements(objects, options);
          svgObjects.set({
              left: 100,
              top: 100,
              class: 'plot',
              subClass:'venn',
              originX: 'center',
              originY: 'center',
          });

          // Add the grouped SVG objects to the Fabric.js canvas and render it
          canvas.add(svgObjects).renderAll();
      } catch (error) {
          console.error('Error adding SVG objects to the canvas:', error);
      }
  });
};

// Example usage: pass the hardcoded chartData to draw the Venn diagram
drawVennDiagram(chartData, canvas);
return;


      case 'boxPlot':
        const width = 600;
        const height = 300;

        const boxPlotSvg = d3.select(document.createElement('div'))
          .append('svg')
          .attr('width', width)
          .attr('height', height);

        const xScale = d3.scaleBand().range([0, width]).padding(0.1);
        const yScale = d3.scaleLinear().range([height, 0]);

        const boxes = chartData;

        xScale.domain(boxes.map((_, i) => i));
        yScale.domain([0, d3.max(boxes.flatMap(box => box.quartiles.concat(box.outliers)))]);

        boxPlotSvg.selectAll('.box')
          .data(boxes)
          .enter().append('g')
          .attr('class', 'box')
          .attr('transform', (d, i) => `translate(${xScale(i)}, 0)`)
          .each(function (d) {
            const boxGroup = d3.select(this);
            const boxWidth = xScale.bandwidth();

            boxGroup.append('rect')
              .attr('x', boxWidth / 4)
              .attr('y', yScale(d3.max(d.quartiles)))
              .attr('width', boxWidth / 2)
              .attr('height', yScale(d3.min(d.quartiles)) - yScale(d3.max(d.quartiles)))
              .attr('stroke', 'black')
              .attr('fill', d.color || 'lightblue'); 

            boxGroup.append('line')
              .attr('x1', boxWidth / 4)
              .attr('x2', 3 * boxWidth / 4)
              .attr('y1', yScale(d.quartiles[1]))
              .attr('y2', yScale(d.quartiles[1]))
              .attr('stroke', 'black');

            boxGroup.selectAll('.outlier')
              .data(d.outliers)
              .enter().append('circle')
              .attr('class', 'outlier')
              .attr('cx', boxWidth / 2)
              .attr('cy', d => yScale(d))
              .attr('r', 3)
              .attr('fill', 'red');

            boxPlotSvg.append('g')
              .attr('transform', `translate(0,${height})`)
              .call(d3.axisBottom(xScale));

            boxPlotSvg.append('g')
              .call(d3.axisLeft(yScale));
          });

        const svgString = new XMLSerializer().serializeToString(boxPlotSvg.node());

        fabric.loadSVGFromString(svgString, (objects, options) => {
          if (!objects || objects.length === 0) {
            console.error('No objects were parsed from the SVG string.');
            return;
          }

          try {
            const svgObjects = fabric.util.groupSVGElements(objects, options);
            svgObjects.set({
              left: 100,
              top: 100,
              class:'plot',
              subClass:'box',
              originX: 'center',
              originY: 'center',
            });

            canvas.add(svgObjects).renderAll();
          } catch (error) {
            console.error('Error adding SVG objects to the canvas:', error);
          }
        });

        return;

      default:
        return;
    }

    canvas.add(newShape);
    // canvas.renderAll();  
  };

  const changeVennCircleColors = (object, colors) => {
    console.log("changeVennCircleColors: objects: ", object, colors);
      if (object.type === 'path') {
        object.set('fill', colors || object.fill);
      }
    canvas.renderAll(); 
  };
  const changeWhiskerBoxColors = (object, colors) => {
    console.log("changeWhiskerBoxColors: objects: ", object, colors);
      if (object.type === 'rect') {
        object.set('fill', colors || object.fill);
      }
    canvas.renderAll(); 
  };
  const changeOutlierColors = (object, newColor) => {
    if (object.type === 'circle') {
      object.set('fill', newColor || object.fill); 
    }
    canvas.renderAll();
  };
  
  const toggleOrientation = (orientation) => {
    if (canvas) {
      let newWidth, newHeight;
  
      if (orientation === "Portrait") {
        newWidth = canvas.getHeight(); 
        newHeight = canvas.getWidth(); 
      console.log('toggleOrientation : Portrait', newWidth,newHeight);

      } else if (orientation === "Landscape") {
        newWidth = canvas.getWidth(); 
        newHeight = canvas.getHeight(); 
      }
  
      setSize({ width: newWidth, height: newHeight });
      
      canvas.setWidth(newWidth);
      canvas.setHeight(newHeight);
  
      // Optionally update zoom or viewport transform here
      // canvas.setZoom(zoom);
      // canvas.viewportTransform = [1, 0, 0, 1, 0, 0]; // Reset viewport transform
  
      canvas.renderAll(); // Ensure canvas is redrawn
  
      console.log('toggleOrientation', orientation, size, canvas.getHeight(), canvas.getWidth());
    }
  };
  
  

const handlePresentation = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen().then(() => {
      setIsFullscreen(true);
    }).catch((err) => {
      alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
    });
  } else {
    document.exitFullscreen().then(() => {
      setIsFullscreen(false);
    });
  }
};
useEffect(() => {
  const canvasContainer = document.querySelector('#canvas-container');
  console.log('canvasContainer: canvasContainer', canvasContainer)
  if (isFullscreen === true) {
    canvasContainer.style.marginTop = '0px';
    canvasContainer.style.alignContent = 'center';

    canvasContainer.style.height = `${window.innerHeight}px`;
    canvasContainer.style.backgroundColor = `black`;

    canvasContainer.style.width = `100%`;
      // canvas.setHeight(window.innerHeight);
      // canvas.setWidth(size.width);
    canvasContainer.style.paddingTop = '0px';


  } else {
    if(canvas){
      updateGuides();
      adjustCanvasContainerDimensions();

      // canvas.setHeight(size.height*zoom);
      // canvas.setWidth(size.width*zoom);
      // canvas.setZoom(zoom); 
      // canvasContainer.style.marginTop = '0px';   
      canvasContainer.style.alignContent = '';
      canvasContainer.style.backgroundColor = `transparent`;
      canvasContainer.style.alignContent = 'bottom';


    }
  }
}, [isFullscreen]);

useEffect(() => {
  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  document.addEventListener('fullscreenchange', handleFullscreenChange);

  return () => {
    document.removeEventListener('fullscreenchange', handleFullscreenChange);
  };
}, []);

const [colors, setColors] = useState([]);
const [datasetData, setDatasetData] = useState([]); // State to manage datasets' data

const handleColorChange = (color, index) => {
  console.log('handleColorChange:', color, index);

  const newColors = [...colors];
  newColors[index] = color;
  setColors(newColors);

  const updatedChartData = {
    ...activeObj[0].chart,
    data: {
      ...activeObj[0].chart.data,
      datasets: [
        {
          ...activeObj[0].chart.data.datasets[0],
          backgroundColor: newColors,
        },
      ],
    },
  };

  console.log('handleColorChange: updatedChartData:', updatedChartData, newColors);

  handleUpdateChart(updatedChartData);
};

const handleDatasetDataChange = (newData, index) => {
  console.log('handleDatasetDataChange:', newData, index);

  const newDatasetData = [...datasetData];
  newDatasetData[index] = newData;
  setDatasetData(newDatasetData);

  const updatedChartData = {
    ...activeObj[0].chart,
    data: {
      ...activeObj[0].chart.data,
      datasets: [
        {
          ...activeObj[0].chart.data.datasets[0],
          data: newDatasetData,
        },
      ],
    },
  };

  console.log('handleDatasetDataChange: updatedChartData:', updatedChartData, newDatasetData);

  handleUpdateChart(updatedChartData);
};

const handleBorderColorChange = (newColor) => {
  const updatedChartData = {
    ...activeObj[0].chart,
    data: {
      ...activeObj[0].chart.data,
      datasets: [
        {
          ...activeObj[0].chart.data.datasets[0],
          borderColor: newColor,
        },
      ],
    },
  };

  handleUpdateChart(updatedChartData);
};


const handleUpdateChart = (updatedChartData) => {
  activeObj[0].set({
    chart: updatedChartData,
  });

  canvas.renderAll(); // Re-render the Fabric.js canvas
};




  return (
    <div style={{ display: 'flex', overflow: "hidden", background: "gray", }}>
      {!isFullscreen&&<Sidebar
        onAddChart = {handleAddChart}
        combineSelectedObjectsDifference={combineSelectedObjectsDifference}
        combineSelectedObjectsFragmentation={combineSelectedObjectsFragmentation}
        combineSelectedObjectsIntersection={combineSelectedObjectsIntersection}
        combineSelectedPaths={combineSelectedPaths}
        combineSelectedPathsExclusion={combineSelectedPathsExclusion}
        originalVisibility={originalVisibility}
        handleChangeView={handleChangeView}
        canvasArray={canvases}
        activeCanvasIndex={activeCanvasIndex}
        onAddCanvas={addNewCanvas}
        canvasImage={canvasImage}
        sidebarRef={sidebarRef}
        selectedStrokeColor={selectedStrokeColor}
        setSelectedStrokeColor={setSelectedStrokeColor}
        appBarRef1={appBarRef1}
        appBarRef2={appBarRef2}
        setOpacity={setOpacity}
        opacity={opacity}
        changeOpacity={changeOpacity}
        pathForPattern={path}
        mainCanvas={canvas}
        onSizeChange={handleSizeChange}
        setSize={setSize}
        size={size}
        switchCanvas={switchCanvas}
        onPatternAlongPath={() => handlePathCreated(canvas, path)}
        onPredifenedSizeChange={handlePredefinedSizeChange}
        onDownloadSVG={() => handleDownloadSVG(canvas)}
        onAddStar={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'star')}
        onAddTrapezium={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'trapezium')}
        onAddPolygon={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'polygon')}
        onAddLine={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'line')}
        onAddArrow={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'arrow')}
        onAddRectangle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'rectangle')}
        onAddCircle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'circle')}
        onAddTriangle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'triangle')}
        onAddSquare={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'square')}
        handleDrawingMode={handleDrawingMode}
        activeObj={activeObj}
        onFreeDrawing={() => handleFreeDrawingClick(canvas)}
        areaOfSpray={areaOfSpray}
        setAreaOfSpray={setAreaOfSpray}
        numberOfCopies={numberOfCopies}
        spraySelectRef={spraySelectRef}
        setNumberOfCopies={setNumberOfCopies}
        onSetColor={setSelectedColor}
        onCanvasBgColorChange={(e) => handleSetColor(e, canvas)}
        fillColor={selectedColor}
        handleSpraying={handleSpraying}
        opacityObj={opacityObj}
        changeOpacityOfObj={changeOpacityOfObj}
        onEraser={() => handleEraserClick(canvas, isErasing, setIsErasing)}
        onUploadSVG={(event) => handleUploadSVG(event, canvas)}
      />}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {activeObj && 
        (!activeObj[0].chart && activeObj[0].class!=='chart')?!activeObj[0].chart && activeObj[0].class!=='plot'?
          <div style={{ padding: '10px', overflow: "auto", maxHeight: "500px", maxWidth: "300px" }}>
            <div style={{ padding: "10px" }}>
              <Typography variant='h6' align='center'>Edit Shape</Typography>

            </div>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleDeleteObject}
              style={{ marginTop: '10px' }}
            >
              {activeObj?.length === 1 ? 'Delete Object':'Delete Objects'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={addCustomControls}
              style={{ marginTop: '10px' }}
            >
              Skew/Rotational Controls
            </Button>
            <TextField label="Unit" type="number" value={activeObj[0].unit || 'px'} onChange={(e) => handlePropertyChange('unit', e.target.value)} select fullWidth margin="normal">           
             <MenuItem value="px">Pixels (px)</MenuItem>            
             <MenuItem value="cm">Centimeters (cm)</MenuItem>            
             <MenuItem value="mm">Millimeters (mm)</MenuItem>            
             <MenuItem value="in">Inches (in)</MenuItem>            
             <MenuItem value="feet">Feet (feet)</MenuItem>          
            </TextField>

            <StyledSubMenuContainer>
            {activeObj[0].class == 'circle' &&
              <>
                <TextField
                  label="Radius"
                  type="number"
                  value={activeObj[0].radius}
                  onChange={(e) => handlePropertyChange('radius', parseFloat(e.target.value))}
                  fullWidth
                  margin="normal" />

                <TextField
                  label="Start Angle"
                  type="number"
                  value={startAngle}
                  onChange={handleStartAngleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="End Angle"
                  type="number"
                  value={endAngle}
                  onChange={handleEndAngleChange}
                  fullWidth
                  margin="normal"
                />

                <FormControl fullWidth margin="normal">
                  <InputLabel>Angle Type</InputLabel>
                  <Select
                    value={angleType}
                    onChange={handleAngleTypeChange}
                  >
                    <MenuItem value="arc">Arc</MenuItem>
                    <MenuItem value="chord">Chord</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
            {activeObj[0]?.class === 'star' || activeObj[0]?.class === 'polygon' ?
              <>
                <TextField
                  label="Corners"
                  type="number"
                  value={activeObj[0].corners}
                  onChange={(e) => activeObj[0]?.class === 'star' ? handleStarCornersChange(e.target.value) : handlePolygonCornersChange(e.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: 3 }} // Star must have at least 5 corners
                />
                {activeObj[0]?.class !== 'polygon' && <TextField
                  inputMode='numeric'
                  inputProps={{
                    step: "0.01",
                    min: "0",
                    max: "1"
                  }}
                  label="Spoke Ratio"
                  type="number"

                  value={activeObj[0]?.spokeRatio} // Default value, adjust as needed
                  onChange={(e) => handleSpokeRatioChange(parseFloat(e.target.value))}
                  fullWidth
                  margin="normal"
                />}
              </>
              : null}
            {activeObj[0]?.class && activeObj[0]?.class !== "circle" && activeObj[0]?.class !== "line" && (
              <>



                <TextField
                  label="Distortion Factor"
                  type="number"
                  onChange={(e) => handlePolygonDistortionChange(e.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    step: "0.01",
                    min: "0.1",
                    max: "10"
                  }}
                />
              </>

            )}
            {/* Add your popover content here */}
            {activeObj[0].class !== "circle" && activeObj[0].class !== "line" &&
              <><TextField
                label="Width"
                type="number"
                value={activeObj[0].width / conversionRates[activeObj[0].unit]}
                onChange={(e) => handlePropertyChange('width', e.target.value ? parseFloat(e.target.value) : 0.1)}
                fullWidth

                margin="normal"

              />
                <TextField
                  label="Height"

                  type="number"
                  value={activeObj[0].height / conversionRates[activeObj[0].unit]}
                  onChange={(e) => handlePropertyChange('height', e.target.value ? parseFloat(e.target.value) : 0.1)}
                  fullWidth
                  margin="normal"
                /></>}
            {activeObj[0].class !== "line" &&
              <><TextField
                label="SkewX"
                type="number"
                value={activeObj[0].skewX}
                onChange={(e) => handlePropertyChange('skewX', parseFloat(e.target.value))}
                fullWidth
                margin="normal"
              />
                <TextField
                  label="SkewY"
                  type="number"
                  value={activeObj[0].skewY}
                  onChange={(e) => handlePropertyChange('skewY', parseFloat(e.target.value))}
                  fullWidth
                  margin="normal"
                /></>}
            {activeObj[0].class !== 'circle' && activeObj[0].class !== 'line' && <TextField
              label="Rotation (In Degree)"
              type="number"
              value={activeObj[0].angle}
              onChange={(e) => handlePropertyChange('angle', parseFloat(e.target.value))}
              fullWidth
              margin="normal"

            />}

            <>
              <Typography >Line Type:</Typography>
              <FormControl sx={{ width: "100%" }}>

                <Select value={lineStyle} onChange={handleChangeLine}>
                  <MenuItem value="solid">Solid</MenuItem>
                  <MenuItem value="dotted">Dotted</MenuItem>
                  <MenuItem value="dashed">Dashed</MenuItem>
                  <MenuItem value="dashed-dotted">Dashed-Dotted</MenuItem>
                </Select>
              </FormControl>
            </>
            {activeObj[0].class !== 'circle' && <div style={{ marginTop: '20px', padding: "20px" }}>
              <Box sx={{ ml: "-10px", display: "flex", justifyContent: "space-between" }} >
                <Typography gutterBottom>Corner Radius: </Typography>
                {cornerRadius}
              </Box>
              {/* <TextField
      label="Corner Radius"
      type="number"
      value={cornerRadius}
      onChange={(e, value) => handleSliderChange(e, value)}
      fullWidth
      margin="normal"
    /> */}
              <Slider
                value={activeObj[0].cornerRadius ? activeObj[0].cornerRadius : cornerRadius}
                min={0}
                max={10}
                step={0.01}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="roundness-slider"
              />

              <FormControl>
                <Typography>Radius Is Factoral:</Typography>
                <RadioGroup
                  aria-label="angle-type"
                  name="angle-type"
                  value={factoral}
                  onChange={(e) => {
                    canvas.renderAll();
                    setFactoral(e.target.value);
                  }}
                >
                  <FormControlLabel value={true} label="Yes" control={<Radio checked={factoral} />} />
                  <FormControlLabel value={false} label="No" control={<Radio checked={!factoral} />} />
                </RadioGroup>

              </FormControl>
            </div>}

            <TextField
              label="X-Axis"
              type="number"
              value={activeObj[0].left}
              onChange={(e) => handlePositionChange('left', parseFloat(e.target.value))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Y-Axis"
              type="number"
              value={activeObj[0].top}
              onChange={(e) => handlePositionChange('top', parseFloat(e.target.value))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fill Color"
              type="color"
              value={activeObj[0].fill}
              onChange={(e) => handlePositionChange('fill', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Stroke Color"
              type="color"
              value={activeObj[0].stroke}
              onChange={(e) => handlePositionChange('stroke', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Stroke Width"
              type="number"
              value={activeObj[0].strokeWidth / conversionRates[activeObj[0].unit]}
              onChange={(e) => handlePositionChange('strokeWidth', e.target.value ? parseFloat(e.target.value) : 0.1)}
              fullWidth
              margin="normal"
            />

            </StyledSubMenuContainer>

          </div>
          :
          <div style={{ padding: '10px', overflow: "auto", maxHeight: "500px", maxWidth: "400px" }}>
            <div style={{ padding: "10px" }}>
              <Typography variant='h6' align='center'>Edit Diagram</Typography>

            </div>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleDeleteObject}
              style={{ marginTop: '10px' }}
            >
              {activeObj?.length === 1 ? 'Delete Object':'Delete Objects'}
            </Button>
            {activeObj?.[0].subClass === 'venn' ? 
            activeObj?.[0]?._objects?.filter((obj) => obj.type === 'path' && obj?.fill?.length > 0).map((dataset, datasetIndex) => (
            <StyledSubMenuContainer key={dataset}>
              <Typography>{dataset.label}</Typography>
                <div style={{ marginBottom: '10px' }}>
                  <Typography>Venn Section {datasetIndex + 1}</Typography>
                  <ColorPicker
                    color={dataset.fill}
                    hideControls hideGradientControls hideInputs hidePresets
                    value={dataset.fill}
                    onChange={(newColor) => changeVennCircleColors(dataset, newColor)}
                  />
                </div>
            </StyledSubMenuContainer>
          ))
          :
          activeObj?.[0]?._objects?.map((dataset, datasetIndex) => {
            if (dataset.type === 'rect' && dataset?.fill?.length > 0) {
              return (
                <div key={datasetIndex} style={{ marginBottom: '20px' }}>
                  <Typography>Box {datasetIndex + 1}</Typography>
                  <ColorPicker
                    color={dataset.fill}
                    value={dataset.fill}
                    hideControls hideGradientControls hideInputs hidePresets

                    onChange={(newColor) => changeWhiskerBoxColors(dataset, newColor)}
                  />
                </div>
              );
            }
          
            if (dataset.type === 'circle' && dataset?.fill?.length > 0) {
              return (
                <div key={datasetIndex} style={{ marginBottom: '20px' }}>
                  <Typography>Outlier {datasetIndex + 1}</Typography>
                  <ColorPicker
                    hideControls hideGradientControls hideInputs hidePresets
                    color={dataset.fill}
                    value={dataset.fill}
                    onChange={(newColor) => changeOutlierColors(dataset, newColor)}
                  />
                </div>
              );
            }
          
            return null;
          })
        }
          
          </div>
          :
          <div style={{ padding: '10px', overflow: "auto", maxHeight: "500px", maxWidth: "400px" }}>
          <div style={{ padding: "10px" }}>
              <Typography variant='h6' align='center'>Edit Chart</Typography>

            </div>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleDeleteObject}
              style={{ marginTop: '10px' }}
            >
              {activeObj?.length === 1 ? 'Delete Object':'Delete Objects'}
            </Button>            
            {activeObj?.[0].chart.type == 'bar' && activeObj?.[0].chart.data.datasets.map((dataset, datasetIndex) => (
        <StyledSubMenuContainer key={datasetIndex}>
        <Typography>{dataset.label}</Typography>
              {dataset.backgroundColor.map((color, colorIndex) => (
  <div key={colorIndex} style={{ marginBottom: '10px',display:'flex',gap:10,justifyContent:'center', flexDirection:'column', alignContent:'center' }}>
  <Typography>Bar {colorIndex + 1}</Typography>
    <TextField
      label={`Value for Bar ${colorIndex + 1}`}
      variant="outlined"
      size="small"
      type="number"
      value={dataset.data[colorIndex]}
      onChange={(e) => handleDatasetDataChange(parseFloat(e.target.value), colorIndex)} // Handle data value change
      // style={{ marginTop: '10px', width: '100%' }}
    />
    {/* ColorPicker for selecting the bar color */}
    <ColorPicker
      color={color}
      value={color}
      hideControls hideGradientControls hideInputs hidePresets
      onChange={(newColor) => handleColorChange(newColor, colorIndex)}
    />

    
  </div>
))}

            </StyledSubMenuContainer>
          ))}  
          {(activeObj?.[0].chart.type == 'pie'  || activeObj?.[0].chart.type == 'doughnut' || activeObj?.[0].chart.type == 'barVertical' || activeObj?.[0].chart.type == 'barHorizontal' || activeObj?.[0].chart.type == 'scatter' || activeObj?.[0].chart.type == 'line') && activeObj?.[0].chart.data.datasets.map((dataset, datasetIndex) => (
        <StyledSubMenuContainer key={datasetIndex}>
          <Typography>{dataset.label}</Typography>
          {dataset.data.map((value, dataIndex) => (
  <div key={dataIndex} style={{ marginBottom: '10px', display: 'flex', gap: 10, justifyContent: 'center', flexDirection: 'column', alignContent: 'center' }}>
    <Typography>Data Point {dataIndex + 1}</Typography>

    {/* TextField for updating the data value of each point */}
    <TextField
      label={`Value for Data Point ${dataIndex + 1}`}
      variant="outlined"
      size="small"
      type="number"
      value={value} // Bind to the current data point value
      onChange={(e) => handleDatasetDataChange(parseFloat(e.target.value), dataIndex)} // Handle data value change
    />

    {/* Optional backgroundColor handling if it exists */}
    {dataset.backgroundColor && dataset.backgroundColor[dataIndex] && (
      <ColorPicker
        color={dataset.backgroundColor[dataIndex]}
        value={dataset.backgroundColor[dataIndex]}
        hideControls hideGradientControls hideInputs hidePresets
        onChange={(newColor) => handleColorChange(newColor, dataIndex)} // Handle background color change
      />
    )}
  </div>
))}

{/* Border Color for the entire dataset (not for individual points) */}
<div style={{ marginBottom: '10px', display: 'flex', gap: 10, justifyContent: 'center', flexDirection: 'column', alignContent: 'center' }}>
  <Typography>Border Color</Typography>
  
  <ColorPicker
    color={dataset.borderColor}
    value={dataset.borderColor}
    hideControls hideGradientControls hideInputs hidePresets
    onChange={(newColor) => handleBorderColorChange(newColor)} // Handle border color change
  />
</div>

        </StyledSubMenuContainer>
          ))}
          </div>
          }
      </Popover>
      <div id="canvas-container"
        ref={canvasRef}
      >
        {!isFullscreen&&<Tooltip arrow title='Guide Options'>
      <Button 
        sx={{
          position: "absolute",
          background: lockGuides.length > 0 ? "#d1d3d8" : "#f0f2f7",
          cursor: 'pointer',
          zIndex: 1000,
          color: "black",
          minWidth: 0,
          p: 0,
          height: "29px",
          width: "29px",
          borderRadius: 0,
          boxShadow: 2,
          '&:hover': {
            background: lockGuides.length > 0 ? "#b0b2b8" : "#e2e4e8"
          }
        }}
        onClick={handleMenuOpen}
      >
        <Lock sx={{ minWidth: 0, fontSize: 16 }} />
      </Button>
      </Tooltip>}
      
      <Menu
        anchorEl={anchorEl1}
        open={Boolean(anchorEl1)}
        sx={{
          ml:'30px'
        }}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {setShowRanges(!showRanges); updateGuideRanges(!showRanges)}}>
          {showRanges?"Hide Shape Ranges":'Show Shape Ranges'}
        </MenuItem>
        <MenuItem onClick={() => handleToggleLock("add")}>
          {lockGuides.includes("add") ? "Unlock Add" : "Lock Add"}
        </MenuItem>
        <MenuItem onClick={() => handleToggleLock("change")}>
          {lockGuides.includes("change") ? "Unlock Change" : "Lock Change"}
        </MenuItem>
        <MenuItem onClick={() => handleToggleLock("remove")}>
          {"Remove All Guides"}
        </MenuItem>
        <MenuItem onClick={handleUnlockAll}>
          Unlock All
        </MenuItem>
      </Menu>
        {!isFullscreen&&<><div className="ruler horizontal">
          <Guides
            onChangeGuides={handleGuideClick}
            lockGuides={lockGuides}
            ref={horizonalGuidesRef}
            guideStyle={{
              width: containerScrollDimensions.width > 0 ?
                `${containerScrollDimensions.width}px` :
                "auto"
            }}
            dragGuideStyle={{
              width: containerScrollDimensions.width > 0 ?
                `${containerScrollDimensions.width}px` :
                "auto"
            }}

            type="horizontal"

            selectedRanges={objGuideWidth && objGuideWidth}
            selectedBackgroundColor='blue'
            backgroundColor='transparent'
            textColor='black'
            lineColor='black'
            zoom={zoom}
            rulerStyle={{
              width: document.getElementById("canvas-container") ?
                `calc(${document.getElementById("canvas-container").scrollWidth}px - 30px)` :
                "auto",
              height: "100%",
              marginLeft: 30
            }}
            displayDragPos={true}
            displayGuidePos={true}
            useResizeObserver={true}
          />
        </div>
        <div className="ruler vertical">
          <Guides
            onGuideClick={handleGuideClick}
            lockGuides={lockGuides}
            ref={verticalGuidesRef}
            type="vertical"
            guideStyle={{
              height: containerScrollDimensions.height > 0 ?
                `${containerScrollDimensions.height}px` :
                "auto"
            }}
            dragGuideStyle={{
              height: containerScrollDimensions.height > 0 ?
                `${containerScrollDimensions.height}px` :
                "auto"
            }}
            zoom={zoom}
            selectedRanges={objGuideHeight && objGuideHeight}
            selectedBackgroundColor='blue'
            backgroundColor='transparent'
            textColor='black'
            lineColor='black'
            rulerStyle={{
              marginTop: "30px",
              height: document.getElementById("canvas-container")
                ?
                `calc(${document.getElementById("canvas-container").scrollHeight}px - 30px)`
                :
                "auto",
              width: "100%",
            }}
            displayDragPos={true}
            displayGuidePos={true}
            useResizeObserver={true}
          />
        </div></>}
      </div>
      {!isFullscreen&&<Footer handlePresentation={handlePresentation} footerRef={footerRef} toggleOrientation={toggleOrientation} zoom={zoom} onZoomChange={handleZoomChange} toggleVisibility={toggleVisibility} canvas={canvas}/>}
    </div>
  );
};

export default Canvas;
