import { fabric } from 'fabric';

export const createPointControls = (canvas, path) => {
  const pathData = path.path.map(([cmd, ...points]) => {
    if (cmd === 'M' || cmd === 'L') return { x: points[0], y: points[1] };
    else if(cmd === "C") return { x:points[4], y:points[5] };
    return { x: 0, y: 0 };
  });
  console.log("createPointControls: path: pathData: ", path,pathData)

  path.edit = true;
  path.controls = {};

  path.path.forEach((point, index) => {
    if (index > 0) {
      const cp = new fabric.Control({
        actionName: 'modifyPolygon',
        pointIndex: index,
        cornerSize: 10, 
        cornerColor: 'red', 
        selectable: true,
        evented: true,
        hoverCursor: 'pointer',
        left: pathData[index].x,
        top: pathData[index].y,
      });
      console.log(`pathData: ${index}: `, pathData[index])

      cp.positionHandler = function (dim, finalMatrix, fabricObject) {
        if(fabricObject.path[this.pointIndex][0] !== 'Z'){
        console.log("positionHandler: fabricObject:",fabricObject, fabricObject.path[this.pointIndex][1],fabricObject.path[this.pointIndex][2],fabricObject.path[this.pointIndex])
        const x = fabricObject.path[this.pointIndex][1] - fabricObject.pathOffset.x;
        const y = fabricObject.path[this.pointIndex][2] - fabricObject.pathOffset.y;
        return fabric.util.transformPoint(
          { x, y },
          fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
        );
        }else return fabric.util.transformPoint(
          { x:0, y:0 },
          fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix()))
      };

      cp.actionHandler = function (eventData, transform, x, y) {
        const polygon = transform.target;
        const currentControl = polygon.controls[polygon.__corner];
        const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
        const polygonBaseSize = getObjectSizeWithStroke(polygon);
        const size = polygon._getTransformedDimensions(0, 0);
        const moveFactor = 5;

        const finalPointPosition = {
          x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
          y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };

        polygon.path[currentControl.pointIndex][1] = finalPointPosition.x;
        polygon.path[currentControl.pointIndex][2] = finalPointPosition.y;

        const adjacentPoints = getAdjacentPoints(polygon.path, currentControl.pointIndex, 35);
        adjacentPoints.forEach(pointIndex => {
          if(polygon && polygon.path[pointIndex] && polygon.path[pointIndex][0] !== 'Z')
          {polygon.path[pointIndex][1] += (finalPointPosition.x - polygon.path[currentControl.pointIndex][1]) * moveFactor;
          polygon.path[pointIndex][2] += (finalPointPosition.y - polygon.path[currentControl.pointIndex][2]) * moveFactor;}
          else if (polygon.path[pointIndex]){
            polygon.path[pointIndex][1] = 0;
            polygon.path[pointIndex][2] = 0;

          }
        });

        polygon.dirty = true;
        polygon.canvas.requestRenderAll();
        return true;
      };

      path.controls['p' + index] = cp;
      path.selectable = true;

    }
  });

  canvas.setActiveObject(path);
  canvas.requestRenderAll();
};

export const createEllipseControls = (canvas, ellipse) => {
  const { left, top, rx, ry } = ellipse;
  const angle = ellipse.angle || 0;

  ellipse.controls = {};

  // Control point for top-left
  const cpTopLeft = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: -rx, y: -ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.topLeft = cpTopLeft;

  // Control point for bottom-right
  const cpBottomRight = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: rx, y: ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.bottomRight = cpBottomRight;

  // Add controls to ellipse
  ellipse.setControlsVisibility({
    tl: true,
    br: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
  });

  canvas.renderAll();
};

export const polygonPositionHandler = function (dim, finalMatrix, fabricObject) {
  const x = fabricObject.path[this.pointIndex][1] - fabricObject.pathOffset.x;
  const y = fabricObject.path[this.pointIndex][2] - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    { x, y },
    fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
  );
};

export const getObjectSizeWithStroke = object => {
  const stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
};

export const actionHandler = (eventData, transform, x, y) => {
  const polygon = transform.target;
  const currentControl = polygon.controls[polygon.__corner];
  const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
  const polygonBaseSize = getObjectSizeWithStroke(polygon);
  const size = polygon._getTransformedDimensions(0, 0);
  const moveFactor = 5;

  const finalPointPosition = {
    x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
    y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
  };

  polygon.path[currentControl.pointIndex][1] = finalPointPosition.x;
  polygon.path[currentControl.pointIndex][2] = finalPointPosition.y;

  const adjacentPoints = getAdjacentPoints(polygon.path, currentControl.pointIndex, 35);
  adjacentPoints.forEach(pointIndex => {
    polygon.path[pointIndex][1] += (finalPointPosition.x - polygon.path[currentControl.pointIndex][1]) * moveFactor;
    polygon.path[pointIndex][2] += (finalPointPosition.y - polygon.path[currentControl.pointIndex][2]) * moveFactor;
  });

  polygon.dirty = true;
  polygon.canvas.requestRenderAll();
  return true;
};

function getAdjacentPoints(path, currentIndex, count) {
  const totalPoints = path.length;
  let indices = [];

  for (let i = 1; i <= count; i++) {
    const prevIndex = (currentIndex - i + totalPoints) % totalPoints;
    const nextIndex = (currentIndex + i) % totalPoints;
    indices.push(prevIndex, nextIndex);
  }

  return indices;
}

export const anchorWrapper = (anchorIndex, fn) => {
  return function (eventData, transform, x, y) {
    const fabricObject = transform.target;
    const absolutePoint = fabric.util.transformPoint({
      x: fabricObject.path[anchorIndex][1] - fabricObject.pathOffset.x,
      y: fabricObject.path[anchorIndex][2] - fabricObject.pathOffset.y,
    }, fabricObject.calcTransformMatrix());
    const actionPerformed = fn(eventData, transform, x, y);
    const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
    const newX = (fabricObject.path[anchorIndex][1] - fabricObject.pathOffset.x) / polygonBaseSize.x;
    const newY = (fabricObject.path[anchorIndex][2] - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
};
