import React, { useState } from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const BoxPlotForm = ({ onSubmit }) => {
  const [boxes, setBoxes] = useState([{ quartiles: '', outliers: '', color: '#0000FF' }]);

  const handleAddBox = () => {
    // Add a new box with default values
    setBoxes([...boxes, { quartiles: '', outliers: '', color: '#0000FF' }]);
  };

  const handleRemoveBox = (index) => {
    // Remove the box at the given index
    const updatedBoxes = boxes.filter((_, i) => i !== index);
    setBoxes(updatedBoxes);
  };

  const handleBoxChange = (index, key, value) => {
    const updatedBoxes = boxes.map((box, i) => {
      if (i === index) {
        return { ...box, [key]: value };
      }
      return box;
    });
    setBoxes(updatedBoxes);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Process the boxes to ensure they have the correct format
    const processedBoxes = boxes.map(box => ({
      quartiles: box.quartiles.split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num)),
      outliers: box.outliers.split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num)),
      color: box.color
    }));

    onSubmit('boxPlot', processedBoxes);
    console.log('handleSubmit: Box Plot Form: ', processedBoxes);
  };

  return (
    <Box sx={{ px: 2, py: 2, maxWidth: '600px' }}>
      
      <Box component="form" onSubmit={handleSubmit}>
        {boxes.map((box, index) => (
          <Box key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', padding: '15px 5px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Typography>Box {index + 1}</Typography>
              <IconButton color="error" onClick={() => handleRemoveBox(index)}>
                <Remove />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              label={`Box ${index + 1} Quartiles`}
              placeholder="Q1,Q2,Q3"
              value={box.quartiles}
              onChange={(e) => handleBoxChange(index, 'quartiles', e.target.value)}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              fullWidth
              label="Box Outliers"
              placeholder="Outlier1,Outlier2"
              value={box.outliers}
              onChange={(e) => handleBoxChange(index, 'outliers', e.target.value)}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              type="color"
              label={`Box ${index + 1} Color`}
              value={box.color}
              onChange={(e) => handleBoxChange(index, 'color', e.target.value)}
              sx={{ minWidth: '250px' }}
            />
          </Box>
        ))}
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleAddBox}>
            <Add /> Add Box
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Generate Box Plot
        </Button>
      </Box>
    </Box>
  );
};

export default BoxPlotForm;
