import React, { useEffect,useState } from 'react';
import {
  TabContext,
  TabPanel
} from '@mui/lab';
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  Slider,
  InputAdornment,
} from '@mui/material';
import { Add, Clear, Remove } from '@mui/icons-material';
import { PopoverListButtons, StyledShapeMenuContainer, StyledSubMenu, StyledSubMenuContainer, StyledSubMenuTitleContainer } from '../../../Utils/styledComps';
import { FaChartSimple, FaShapes } from 'react-icons/fa6';
import PieChartForm from '../ChartForms/PieChart';
import LineChartForm from '../ChartForms/LineChart';
import ScatterChartForm from '../ChartForms/ScatterChart';
import { VennDiagramChart } from 'chartjs-chart-venn';
import VennForm from '../ChartForms/VennChart';
import BoxPlotForm from '../ChartForms/WhiskerChart';
const CustomShapesPopoverContent = ({ 
  shapes, 
  handlePopoverClose,
charts,
selectedFeature,
onAddChart,
setSelectedFeature,
currentPopover }) => {
  const [selectedChart, setSelectedChart] = useState('');

  useEffect(()=>{
    if(selectedFeature !== 'Shapes' && selectedFeature !== 'Charts'){
      setSelectedFeature('Shapes');
    }
  },[])
  const [entries, setEntries] = useState([
    { label: '', data: '', color: '' },
  ]);

  const handleChange = (index, field, value) => {
    const newEntries = [...entries];
    newEntries[index][field] = value;
    setEntries(newEntries);
  };

  const handleAddEntry = () => {
    setEntries([...entries, { label: '', data: '', color: '' }]);
  };

  const handleRemoveEntry = (index) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const labels = entries.map(entry => entry.label);
    const data = entries.map(entry => parseFloat(entry.data) || 0);
    const backgroundColor = entries.map(entry => entry.color);
    onAddChart('barVertical', {
      labels,
      data,
      backgroundColor,
    });
  };

  return (
    <TabContext value={selectedFeature}>
      <Box sx={{ overflow: "hidden",}}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", paddingLeft: "10px" }}>
          <Typography sx={{ m: 0, pl: 2, fontSize: 18, color: '#f0f2f7', fontWeight: 800 }}>
            {currentPopover ? currentPopover.charAt(0).toUpperCase() + currentPopover.slice(1) : null}
          </Typography>
          <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
            <Clear />
          </IconButton>
        </div>
        <Tabs
          value={selectedFeature}
          onChange={(event, newValue) => setSelectedFeature(newValue)}
          variant="fullWidth"
          sx={{ borderBottom: 1, p: 0, borderColor: 'divider' }}
        >
            <Tooltip arrow title={'Shapes'}>
              <Tab
                sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                icon={<FaShapes/>}
                value={'Shapes'}
              />
            </Tooltip>
            <Tooltip arrow title={'Charts'}>
              <Tab
                sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                icon={<FaChartSimple/>}
                value={'Charts'}
              />
            </Tooltip>
        </Tabs>
          <Box sx={{ overflow: "auto",height: "430px", }}>
          <TabPanel value={'Shapes'} sx={{ overflow: "auto",  width: '300px',padding:0}}>
          <StyledSubMenuContainer >
            <StyledSubMenuTitleContainer >  
              <StyledSubMenu >Basics</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "basics").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer> 
              <StyledSubMenu >Lines </StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "lines").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Arrows</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "arrows").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Misc.</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "specials").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Equations & Flow Chart Shapes</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "flowChartShapes" || item.class === "equations").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Banners</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "banners").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Speech Bubbles</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "speech").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Library</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "images").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
          </StyledSubMenuContainer>
          </TabPanel>
          <TabPanel value={'Charts'} sx={{ overflow: "auto", width: '300px',padding:0}}>
          <StyledSubMenuContainer sx={{height:'100%'}}>
          <StyledShapeMenuContainer sx={{gap:5}}>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Charts</StyledSubMenu>
            </StyledSubMenuTitleContainer>
              {charts.map((item,index) => (
                <Tooltip key={index} title={item.label}>
                <PopoverListButtons sx={{border:'1px solid white'}} onClick={()=>setSelectedChart(item.label)}>
                  {item.icon}
                </PopoverListButtons>
              </Tooltip>
              ))}
              {selectedChart !== '' && 
              <StyledSubMenuTitleContainer > 

              <StyledSubMenu >{selectedChart}</StyledSubMenu>

              
            </StyledSubMenuTitleContainer>}
              {selectedChart !== '' && 
          selectedChart === 'Histograms' 
            ?
            <Box sx={{px:1.5, pb:2}}>
              
              <Box component="form" onSubmit={handleSubmit}>
                <Box >
                  {entries.map((entry, index) => (
                    <Box key={index} sx={{my:2}}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography fontSize={16}>Data Set: {index+1}</Typography> 
                      {entries.length > 1 && (
                          <IconButton onClick={() => handleRemoveEntry(index)}>
                            <Remove />
                          </IconButton>
                        )}
                      </Box>
                      <Box display="flex" flexDirection={'column'} gap={3} alignItems="center">
                        <TextField
                          label="Label"
                          value={entry.label}
                          onChange={(e) => handleChange(index, 'label', e.target.value)}
                          sx={{ minWidth: '250px' }}                        />
                        <TextField
                          label="Data"
                          type="number"
                          value={entry.data}
                          onChange={(e) => handleChange(index, 'data', e.target.value)}
                          sx={{ minWidth: '250px' }}                        />
                        <TextField
                          label="Background Color"
                          value={entry.color}
                          type='color'
                          onChange={(e) => handleChange(index, 'color', e.target.value)}
                          sx={{ minWidth: '250px' }}                        />
                        
                      </Box>
                    </Box>
                  ))}
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button type="button" variant="contained" color="secondary" onClick={handleAddEntry}>
                      <Add /> 
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Add Bar Chart
                    </Button>
                    </Box>
                </Box>
              </Box>
            </Box>
            : selectedChart === 'Pie Chart'  ?
            <Box>
              <PieChartForm onAddChart={onAddChart} />

            </Box>
            : selectedChart === 'Line Chart' ? 
            <Box>
              <LineChartForm onAddChart={onAddChart}/>
            </Box>
            : selectedChart === 'Scatter Plot' ? 
            <Box>
              <ScatterChartForm onAddChart={onAddChart}/>
            </Box>
            : selectedChart === 'Venn Diagram' ? 
            <Box>
              <VennForm onSubmit={onAddChart}/>
            </Box>
            : selectedChart === 'Whisker Chart' ? 
            <Box>
              <BoxPlotForm onSubmit={onAddChart}/>
            </Box>
            : null
          }
            </StyledShapeMenuContainer>
            </StyledSubMenuContainer>
          </TabPanel>
          
      </Box>
      </Box>
      
    </TabContext>
    
  );
};

export default CustomShapesPopoverContent;
