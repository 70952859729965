import React, { useState } from "react";
import { StyledBottomNav, StyledListButton, StyledSlider } from "../../Utils/styledComps";
import { Paper, Box, Tooltip } from "@mui/material";
import { CenterFocusStrongOutlined, Fullscreen, ZoomIn, ZoomOut } from "@mui/icons-material";
import { IoTabletLandscapeOutline, IoTabletPortraitOutline } from "react-icons/io5";
import { PiPresentation } from "react-icons/pi";

const Footer = ({ onZoomChange, canvas, handlePresentation, zoom, footerRef, toggleOrientation, toggleVisibility }) => {
  function ValueLabelComponent(props) {
    const { children, value } = props;
    return (
      <Tooltip enterTouchDelay={0} arrow placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }
  const [orientation, setOrientation] = useState('Portrait');

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  };


  const handleOrientation = (orientation)=>{
    toggleOrientation(orientation); 
    if(orientation === "Portrait") {
       setOrientation('Landscape') 
     }else {
      setOrientation('Portrait') 
     }
  }
  return (
    <Paper ref={footerRef} sx={{ position: 'fixed', bottom: 3, left: 0, right: 0, height: "50px" }} elevation={24}>
      <StyledBottomNav>
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Tooltip arrow title="Zoom Out">
            <StyledListButton sx={{ height: "100%" }}>
              <ZoomOut />
            </StyledListButton>
          </Tooltip>
          <StyledSlider
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            valueLabelDisplay="auto"
            shiftStep={100}
            step={10}
            marks
            defaultValue={zoom * 100}
            min={0}
            max={1000}
            onChange={onZoomChange}
          />
          <Tooltip arrow title="Zoom In">
            <StyledListButton sx={{ height: "100%" }}>
              <ZoomIn />
            </StyledListButton>
          </Tooltip>

        </Box>
        <Tooltip arrow title="Center visible">
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={toggleVisibility}>
            <CenterFocusStrongOutlined />
          </StyledListButton>
        </Tooltip>
        {/* <Tooltip arrow placement="top" title="Full Screen">
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={handleFullscreen}>
            <Fullscreen />
          </StyledListButton>
        </Tooltip> */}
        
        <Tooltip arrow placement="top" title="Presentation Mode">
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={handlePresentation}>
            <PiPresentation />
          </StyledListButton>
        </Tooltip>
        <Tooltip arrow placement="top" title={orientation === "Portrait" ? 'Landscape Mode' : 'Portrait Mode'}>
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={() => { handleOrientation(orientation)}}>
            {orientation === "Portrait" ? <IoTabletPortraitOutline /> : <IoTabletLandscapeOutline />}
          </StyledListButton>
        </Tooltip>
        <Tooltip arrow placement="top" title={`Canvas Dimensions: Height:${Math.floor(canvas?.getHeight()/canvas?.getZoom())} || Width:${Math.floor(canvas?.getWidth()/canvas?.getZoom())} || Zoom:${canvas?.getZoom()}`}>
          <div style={{display:'flex', gap:10,marginRight:20}}>
            <div style={{padding:0, display:'flex', flexDirection:'column',justifyContent:"center", alignContent:'center'}}>
              <p style={{padding:0,margin:0, fontSize:'10px'}}>
                H-{Math.floor(canvas?.getHeight()/canvas?.getZoom())}
              </p>

              <p style={{padding:0,margin:0, fontSize:'10px'}}>
                W-{Math.floor(canvas?.getWidth()/canvas?.getZoom())}
              </p>

              <p style={{padding:0,margin:0, fontSize:'10px'}}>
                Z-{canvas?.getZoom()}
              </p>
            </div>
          
          </div>
        </Tooltip>
      </StyledBottomNav>
    </Paper>
  );
};

export default Footer;
