import React, { useState } from 'react';
import { TextField, Button, Box, IconButton, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const LineChartForm = ({ onAddChart }) => {
  const [entries, setEntries] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#000000',
        backgroundColor: '#000000',
        fill: true,
      },
    ],
  });

  const handleLabelChange = (index, value) => {
    const newLabels = [...entries.labels];
    newLabels[index] = value;
    setEntries({ ...entries, labels: newLabels });
  };

  const handleDatasetChange = (index, field, value) => {
    const newDatasets = [...entries.datasets];
    newDatasets[index] = { ...newDatasets[index], [field]: value };
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleDataChange = (datasetIndex, dataIndex, value) => {
    const newDatasets = [...entries.datasets];
    const newData = [...newDatasets[datasetIndex].data];
    newData[dataIndex] = parseFloat(value) || 0;
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleAddDataset = () => {
    setEntries((prevEntries) => ({
      ...prevEntries,
      datasets: [
        ...prevEntries.datasets,
        {
          label: '',
          data: [],
          borderColor: '#000000',
          backgroundColor: '#000000',
          fill: true,
        },
      ],
    }));
  };

  const handleRemoveDataset = (index) => {
    const newDatasets = entries.datasets.filter((_, i) => i !== index);
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleAddLabel = () => {
    setEntries((prevEntries) => ({
      ...prevEntries,
      labels: [...prevEntries.labels, ''],
    }));
  };

  const handleRemoveLabel = (index) => {
    const newLabels = entries.labels.filter((_, i) => i !== index);
    setEntries({ ...entries, labels: newLabels });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddChart('line', entries);
    console.log('Line: handleSubmit: ', entries);
  };

  return (
    <Box sx={{ px: 1.5, pb: 2 }}>
      
      <Box component="form" onSubmit={handleSubmit}>
        <Typography fontSize={18} sx={{ mb: 2 }}>Labels</Typography>
        {entries.labels.map((label, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              label={`Label ${index + 1}`}
              value={label}
              onChange={(e) => handleLabelChange(index, e.target.value)}
              sx={{ mr: 2 }}
            />
            <IconButton onClick={() => handleRemoveLabel(index)}>
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddLabel}>
          <Add /> Add Label
        </Button>

        {entries.datasets.map((dataset, datasetIndex) => (
          <Box key={datasetIndex} sx={{ my: 3 }}>
            <Typography fontSize={18} sx={{ mb: 2 }}>Dataset {datasetIndex + 1}</Typography>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="Dataset Label"
                value={dataset.label}
                onChange={(e) => handleDatasetChange(datasetIndex, 'label', e.target.value)}
                sx={{ mr: 2 }}
              />
              {entries.datasets.length > 1 && (
                <IconButton onClick={() => handleRemoveDataset(datasetIndex)}>
                  <Remove />
                </IconButton>
              )}
            </Box>
            <TextField
              fullWidth
              label="Border Color"
              type="color"
              value={dataset.borderColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'borderColor', e.target.value)}
              sx={{ my: 2 }}
            />
            <TextField
              fullWidth
              label="Background Color"
              type="color"
              value={dataset.backgroundColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'backgroundColor', e.target.value)}
              sx={{ my: 2 }}
            />

            <Typography fontSize={16} sx={{ mb: 2 }}>Data Points</Typography>
            {entries.labels.map((label, dataIndex) => (
              <TextField
                key={dataIndex}
                fullWidth
                label={`Data for ${label || `Label ${dataIndex + 1}`}`}
                type="number"
                value={dataset.data[dataIndex] || ''}
                onChange={(e) => handleDataChange(datasetIndex, dataIndex, e.target.value)}
                sx={{ mb: 2 }}
              />
            ))}
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddDataset}>
          <Add /> Add Dataset
        </Button>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Line Chart
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LineChartForm;
