// src/App.js
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import Canvas from './Components/Canvas/canvas';
import theme from './Utils/theme';
import Homepage from './Components/Home/home';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { Provider } from 'react-redux';
import store from './Redux/store';
import LogIn from './Components/User/Login/login';
import ProtectedRoute from './Utils/protectedPaths';
import CanvasComp from './Components/Canvas/canvasComponent';
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Router>
            <Routes>
              <Route path="/" element={<LogIn />} />
              <Route path="/home" element={<ProtectedRoute element={<Homepage />} />} />
              <Route path="/canvas" element={<ProtectedRoute element={<Canvas />} />} />
              <Route path="/canvasComp" element={<ProtectedRoute element={<CanvasComp />} />} />

            </Routes>
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
