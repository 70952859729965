import React, { useState } from 'react';
import { TextField, Button, Box, IconButton, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const ScatterChartForm = ({ onAddChart }) => {
  const [datasets, setDatasets] = useState([
    {
      label: '',
      data: [{ x: '', y: '' }],
      backgroundColor: '#000000',
    },
  ]);

  const handleDatasetChange = (index, field, value) => {
    const newDatasets = [...datasets];
    newDatasets[index] = { ...newDatasets[index], [field]: value };
    setDatasets(newDatasets);
  };

  const handleDataChange = (datasetIndex, dataIndex, axis, value) => {
    const newDatasets = [...datasets];
    const newData = [...newDatasets[datasetIndex].data];
    newData[dataIndex] = { ...newData[dataIndex], [axis]: parseFloat(value) || '' };
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleAddDataset = () => {
    setDatasets((prevDatasets) => [
      ...prevDatasets,
      {
        label: '',
        data: [{ x: '', y: '' }],
        backgroundColor: '#000000',
      },
    ]);
  };

  const handleRemoveDataset = (index) => {
    const newDatasets = datasets.filter((_, i) => i !== index);
    setDatasets(newDatasets);
  };

  const handleAddDataPoint = (datasetIndex) => {
    const newDatasets = [...datasets];
    const newData = [...newDatasets[datasetIndex].data, { x: '', y: '' }];
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleRemoveDataPoint = (datasetIndex, dataIndex) => {
    const newDatasets = [...datasets];
    const newData = newDatasets[datasetIndex].data.filter((_, i) => i !== dataIndex);
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddChart('scatter', { datasets });
  };

  return (
    <Box sx={{ px: 1.5, pb: 2 }}>
     
      <Box component="form" onSubmit={handleSubmit}>
        {datasets.map((dataset, datasetIndex) => (
          <Box key={datasetIndex} sx={{ my: 3 }}>
            <Typography fontSize={18} sx={{ mb: 2 }}>Dataset {datasetIndex + 1}</Typography>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="Dataset Label"
                value={dataset.label}
                onChange={(e) => handleDatasetChange(datasetIndex, 'label', e.target.value)}
                sx={{ mr: 2 }}
              />
              {datasets.length > 1 && (
                <IconButton onClick={() => handleRemoveDataset(datasetIndex)}>
                  <Remove />
                </IconButton>
              )}
            </Box>
            <TextField
              fullWidth
              label="Background Color"
              type="color"
              value={dataset.backgroundColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'backgroundColor', e.target.value)}
              sx={{ my: 2 }}
            />

            <Typography fontSize={16} sx={{ mb: 2 }}>Data Points</Typography>
            {dataset.data.map((point, dataIndex) => (
              <Box key={dataIndex} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                  fullWidth
                  label={`X Value`}
                  type="number"
                  value={point.x}
                  onChange={(e) => handleDataChange(datasetIndex, dataIndex, 'x', e.target.value)}
                  sx={{ mr: 2 }}
                />
                <TextField
                  fullWidth
                  label={`Y Value`}
                  type="number"
                  value={point.y}
                  onChange={(e) => handleDataChange(datasetIndex, dataIndex, 'y', e.target.value)}
                  sx={{ mr: 2 }}
                />
                {dataset.data.length > 1 && (
                  <IconButton onClick={() => handleRemoveDataPoint(datasetIndex, dataIndex)}>
                    <Remove />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button variant="contained" color="secondary" onClick={() => handleAddDataPoint(datasetIndex)}>
              <Add /> Add Data Point
            </Button>
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddDataset}>
          <Add /> Add Dataset
        </Button>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Scatter Chart
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScatterChartForm;
