import React, { useState } from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const VennForm = ({ onSubmit }) => {
  const [sets, setSets] = useState([{ sets: [''], size: 0, color: '#000000' }]);

  const handleAddSet = () => {
    // Add a new set with default values
    setSets([...sets, { sets: [''], size: 0, color: '#000000' }]);
  };

  const handleRemoveSet = (index) => {
    // Remove the set at the given index
    const updatedSets = sets.filter((_, i) => i !== index);
    setSets(updatedSets);
  };

  const handleSetChange = (index, key, value) => {
    const updatedSets = sets.map((set, i) => {
      if (i === index) {
        const updatedValue = key === 'sets' ? 
          value.split(',').map(str => str.trim()) : value;

        return { ...set, [key]: updatedValue };
      }
      return set;
    });
    setSets(updatedSets);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Process the sets to ensure they have the correct format
    const processedSets = sets.map(set => ({
      ...set,
      sets: set.sets.length === 1 && set.sets[0] === '' ? [] : set.sets
    }));

    onSubmit('venn', processedSets);
    console.log('handleSubmit: Venn Form: ', processedSets);
  };

  return (
    <Box sx={{ px: 2, pb: 2, maxWidth: '400px' }}>
      
      <Box component="form" onSubmit={handleSubmit}>
        {sets.map((set, index) => (
          <Box key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', padding: '15px 5px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Typography>Data Set {index + 1}</Typography>
              <IconButton color="error" onClick={() => handleRemoveSet(index)}>
                <Remove />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              label={`Set ${index + 1} Name`}
              placeholder="Set Name"
              value={set.sets.join(', ')}
              onChange={(e) => handleSetChange(index, 'sets', e.target.value)}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              type="number"
              label="Set Size"
              placeholder="Set Size"
              value={set.size}
              onChange={(e) => handleSetChange(index, 'size', parseInt(e.target.value) || 0)}
              sx={{ minWidth: '250px' }}
            />
            <TextField
              type="color"
              label="Color"
              value={set.color}
              onChange={(e) => handleSetChange(index, 'color', e.target.value)}
              sx={{ minWidth: '250px' }}
            />
          </Box>
        ))}
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleAddSet}>
            <Add /> Add Set
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Generate Venn Diagram
        </Button>
      </Box>
    </Box>
  );
};

export default VennForm;
