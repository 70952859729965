import React, { useState } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { ConversionDropdown } from '../../../Utils/conversions';

const PieChartForm = ({ onAddChart }) => {
  const [entries, setEntries] = useState([{ label: '', data: '', color: '#000000' }]);
  const [chartType, setChartType] = useState('pie');
  const [formats, setFormats] = useState('px');
  const conversionRates = { px: 1, cm: 37.7952756, in: 96, feet: 1152, mm: 3.77953 };

  const handleChange = (index, field, value) => {
    
    const newEntries = [...entries];
    if(field === 'data'){
      value *= conversionRates[formats];
    }
    newEntries[index][field] = value ;
    setEntries(newEntries);
  };

  const handleAddEntry = () => {
    setEntries([...entries, { label: '', data: '', color: '#000000' }]);
  };

  const handleRemoveEntry = (index) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const labels = entries.map((entry) => entry.label);
    const data = entries.map((entry) => parseFloat(entry.data) || 0);
    const backgroundColor = entries.map((entry) => entry.color);
    onAddChart(chartType, {
      labels,
      data,
      backgroundColor,
    });
  };

  return (
    <Box sx={{ px: 1.5, pb: 2 }}>
     
      <RadioGroup
        row
        value={chartType}
        onChange={handleChartTypeChange}
        sx={{ my: 2 }}
      >
        <FormControlLabel value="pie" control={<Radio />} label="Pie" />
        <FormControlLabel value="doughnut" control={<Radio />} label="Doughnut" />
      </RadioGroup>
      <Box component="form" onSubmit={handleSubmit}>
        <Box>
          <ConversionDropdown value={formats} setValue={setFormats}/>
          {entries.map((entry, index) => (
            <Box key={index} sx={{ my: 2 }}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={16}>Data Set: {index + 1}</Typography>
                {entries.length > 1 && (
                  <IconButton onClick={() => handleRemoveEntry(index)}>
                    <Remove />
                  </IconButton>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={3} alignItems="center">
                <TextField
                  label="Label"
                  value={entry.label}
                  onChange={(e) => handleChange(index, 'label', e.target.value)}
                  sx={{ minWidth: '250px' }}/>
                <TextField
                  label="Data"
                  type="number"
                  value={entry.data/conversionRates[formats]}
                  onChange={(e) => handleChange(index, 'data', e.target.value)}
                  sx={{ minWidth: '250px' }}/>
                <TextField
                  label="Background Color"
                  value={entry.color}
                  type="color"
                  onChange={(e) => handleChange(index, 'color', e.target.value)}
                  sx={{ minWidth: '250px' }}/>
              </Box>
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button type="button" variant="contained" color="secondary" onClick={handleAddEntry}>
              <Add />
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add {chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PieChartForm;
