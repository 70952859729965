import { TextField, MenuItem } from "@mui/material";

export const ConversionDropdown = ({value, setValue}) =>{
    // const conversionRates = { px: 1, cm: 37.7952756, in: 96, feet: 1152, mm: 3.77953 };

    return(
        <TextField label="Unit" type="number" value={value} onChange={(e) => setValue(e.target.value)} select fullWidth margin="normal">           
             <MenuItem value="px">Pixels (px)</MenuItem>            
             <MenuItem value="cm">Centimeters (cm)</MenuItem>            
             <MenuItem value="mm">Millimeters (mm)</MenuItem>            
             <MenuItem value="in">Inches (in)</MenuItem>            
             <MenuItem value="feet">Feet (feet)</MenuItem>          
        </TextField>
    )
}   
 